import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { ApiService } from 'src/app/services/api.service';
import { AlertService } from 'src/app/services/alert.service';
import { ProductSql } from 'src/app/types/productSql.model';

export class MyDataSource extends DataSource<ProductSql | undefined> {
  // private _pageSize = Math.ceil((window.screen.height || 1000) / 50);
  private _pageSize = Math.ceil((window.screen.height || 1000) / 60);
  private _cachedData = Array.from<ProductSql>({ length: 1 });
  private _fetchedPages = new Set<number>();
  private _dataStream = new BehaviorSubject<(ProductSql | undefined)[]>(this._cachedData);
  private _subscription = new Subscription();
  public _startPage: number;
  public _endPage: number;
  public length: number;
  private filter: string;
  private typeFilter: string;
  public toHighlight = '';
  public uniqueProduct: ProductSql;

  constructor(private apiService: ApiService, private alertService: AlertService, private id_inventory: number) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<(ProductSql | undefined)[]> {
    this._subscription.add(collectionViewer.viewChange.pipe(debounceTime(300)).subscribe(range => {
      this._startPage = this._getPageForIndex(range.start);
      this._endPage = this._getPageForIndex(range.end - 1);
      for (let page = this._startPage; page <= this._endPage; page++) {
        this._fetchPage(page, this.filter, this.typeFilter);
      }
    }));
    return this._dataStream;
  }

  disconnect(): void {
    this._subscription.unsubscribe();
  }

  setFilters(filter: string, typeFilter: string) {
    this._fetchPage(this._startPage, filter, typeFilter);
  }

  private _getPageForIndex(index: number): number {
    return Math.floor(index / this._pageSize);
  }

  private _fetchPage(page: number, filter?: string, typeFilter?: string) {
    if (typeof filter == "undefined") {
      filter = '';
    }

    if (typeof typeFilter == "undefined") {
      typeFilter = '';
    } else {
      typeFilter.trim();
    }

    if (this.filter == filter && this.typeFilter == typeFilter) {
      if (this._fetchedPages.has(page)) {
        return;
      }
    }

    this.filter = filter;
    this.typeFilter = typeFilter;
    this._fetchedPages.add(page);

    const offset = page * this._pageSize;
    const limit = offset + this._pageSize;
    const request = this.apiService.loadDataSource(this.id_inventory, limit, offset, filter, this.typeFilter);

    request.subscribe(data => {
      if (data['error']) {
        return this.alertService.error(data['error']);
      }

      if (!data) {
        return this.alertService.error('Error al obtener los datos');
      }

      this.toHighlight = data['search'];
      if (this._cachedData.length !== data['total']) {
        this.length = data['total'];
        this._cachedData = Array.from({ length: data['total'] });
        this._fetchedPages = new Set<number>([page]);
      }

      if (data['result'].length == 1) {
        this.uniqueProduct = data['result'][0];
      } else {
        this.uniqueProduct = undefined;
      }
      this._cachedData.splice(offset, data['result'].length, ...data['result']);
      this._dataStream.next(this._cachedData);
    }, (error) => {
      return this.apiService.validateError(error, " obtener datos de SQL.");
    });

  }
}
