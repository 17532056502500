import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { HeaderTarifa, SupplierTarifa } from 'src/app/types/supplier-tarifa.model';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent {
  header: HeaderTarifa = undefined;
  options: string[] = [
    'reference',
    'upc',
    'ean',
    'wholesale_price',
    'price',
    'dto',
  ];

  constructor(private apiService: ApiService, private alertService: AlertService, public dialogRef: MatDialogRef<HeaderComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.apiService.getHeaderTarifaById(this.data.id_file, this.data.id_column).subscribe(r => {
      if (r['error']) {
        return this.alertService.error(r['error']);
      }

      if (Array.isArray(r) && !r.length) {
        r = {
          id: 0,
          id_file: this.data.id_file,
          id_header: this.data.id_column,
          header: this.data.column,
          new_header: '',
          date_add: '',
          date_upd: ''
        }
      }

      this.header = r;
    })
  }

  isUpdated() {
    return !Array.isArray(this.header) && this.header.id > 0;
  }

  getNewHeader() {
    return this.header['new_header'] ?? '';
  }

  updateNewHeader(e) {
    this.header['new_header'] = e.value;
  }

  updateHeader() {
    this.apiService.updateHeaderTarifa(this.header.id_file, this.header.id_header, this.header.new_header).subscribe(r => {
      if (r == -1) {
        return;
      }

      if (r == 0) {
        return this.alertService.error('Error al guardar');
      }

      this.dialogRef.close(this.header);
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addNew() {
    this.apiService.addHeaderTarifa(this.header).subscribe(r => {
      if (r == 0) {
        return this.alertService.error('Error al guardar');
      }

      this.header.id = r;
      this.dialogRef.close(this.header);
    })
  }

  deleteHeader(): void {
    this.apiService.deleteHeaderTarifa(this.header.id_file, this.header.id_header).subscribe(r => {
      if (!r) {
        return this.alertService.error('Error al eliminar');
      }

      this.dialogRef.close(this.header);
    })
  }
}
