<mat-toolbar>
  <span *ngIf="dataSource">{{dataSource.length}} Marcas</span>
  <span class="app-toolbar-filler"></span>
  <app-options-supplier (selectSupplier)="changeSupplier($event)" [discards]="['bydemes', 'visiotech', 'ibd', 'aryan']" [news] = "[]"></app-options-supplier>
  <span class="app-toolbar-filler"></span>
  <button mat-icon-button (click)="getData()" [matTooltip]="'Actualizar'"><mat-icon>refresh</mat-icon></button>
</mat-toolbar>
<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<table mat-table *ngIf="dataSource && dataSource.length > 0" [dataSource]="dataSource" id="table-brands">
  <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns">
    <th mat-header-cell *matHeaderCellDef>
      <span>
        {{translate[col]}}
      </span>
    </th>
    <td mat-cell *matCellDef="let element">
      <div [ngSwitch]="col">
        <div class="btn-edit" *ngSwitchCase="'action'">
          <button mat-icon-button [matTooltip]="element.legend" [color]="element.action == 'add_box' ? 'primary' : 'warn'" (click)="action(element)"><mat-icon>{{element[col]}}</mat-icon></button>
        </div>
        <span *ngSwitchDefault>
          {{element[col]}}
        </span>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [style.background]="row.exist === true ? '#ffebb0': ''"></tr>
</table>
