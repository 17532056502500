import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { AlertService } from '../services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchComponent } from '../search/search.component';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {
  public id_inventory: number;
  public type: string;
  public dataSource: any;

  constructor(private apiService: ApiService, private alertService: AlertService, private route: ActivatedRoute, private router: Router) {
    this.id_inventory = parseInt(this.route.snapshot.paramMap.get('id'));
    this.type = this.route.snapshot.paramMap.get('type') + '';

    if (this.id_inventory < 1) {
      this.router.navigate(['/products/']);
    } else {
      if (this.type == 'validate') {
        this.validateData();
      } else if (this.type == 'update') {
        this.updateData();
      } else if (this.type == 'adjustment') {
        this.validateInventoryAdjustment();
      } else {
        this.router.navigate(['/products/']);
      }
    }

  }

  ngOnInit(): void {
  }

  updateData() {
    this.apiService.updateData(this.id_inventory, false).subscribe(result => {
      if (result['error']) {
        return this.alertService.error(result['error']);
      }

      this.dataSource = [...result];
    }, (error) => {
      return this.apiService.validateError(error, " al actualizar los productos del inventario odoo.");
    });
  }

  validateData() {
    this.apiService.validateData(this.id_inventory).subscribe(result => {
      if (result['error']) {
        return this.alertService.error(result['error']);
      }

      this.dataSource = [...result];
    }, (error) => {
      return this.apiService.validateError(error, " al validar los productos del inventario de odoo.");
    });
  }

  validateInventoryAdjustment() {
    this.apiService.validateInventoryAdjustment(this.id_inventory).subscribe(result => {
      if (result['error']) {
        return this.alertService.error(result['error']);
      }

      this.dataSource = [...result];
    }, (error) => {
      return this.apiService.validateError(error, " al validar los productos del inventario de odoo.");
    });
  }
}
