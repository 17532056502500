import { Component, ElementRef, HostListener, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AlertService } from 'src/app/services/alert.service';
import { Inventory } from 'src/app/types/inventory.model';
import { MyDataSource } from './product-data-source.component';
import { ProductSql } from 'src/app/types/productSql.model';
import { MatDialog } from '@angular/material/dialog';
import { NewProductComponent } from '../new-product/new-product.component';
import { ShowImageComponent } from '../show-image/show-image.component';
import { ProductLog } from '../types/productLog.model';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  host: {
    '(document:keypress)': 'handleKeyPress($event)',
    '(document:keydown)': 'handleKeyDown($event)'
  }
})
export class ProductsComponent implements OnChanges {
  public id: number = 0;
  public inventory: Inventory;
  public data: any;
  public filter: string = '';
  public typeFilter: string = '';
  public ds;
  public activeElement: ProductSql;
  public maxKeyBoardTime: number;
  public timer: number;
  public existInSql: boolean;
  public dialogOpen: boolean = false;
  @ViewChild('qty') qtyElement: ElementRef;
  @ViewChild('qty2') qtyPlusElement: ElementRef;
  public ctrlDown = false;
  public ctrlKey = 17;
  public cmdKey = 91;
  public eanOriginal = '';
  public allowUpdate = false;
  public qty_plus: number = 1;
  public first_open = true;
  public date_update: Date = new Date();
  public logs: ProductLog[] = [];
  public edited: boolean = false;
  public imgDialog: boolean = false;
  public minKeyBoardTime: number;
  public qtyPreUpdate: number;

  @HostListener('paste', ['$event']) onPaste(e: ClipboardEvent) {
    if (event.target['id'] == 'qty_plus' || this.validateEdited()) {
      e.preventDefault();
      return;
    }

    let clipboardData = e.clipboardData;
    this.filter = clipboardData.getData('text');
    if (this.filter.trim().length) {
      this.loadDataSource();
    }
  }

  constructor(private apiService: ApiService, private alertService: AlertService, private route: ActivatedRoute, public dialog: MatDialog, private router: Router) {
    this.id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.getInfoInventory();
    this.existInventoryInSql();
  }

  handleKeyPress(event: KeyboardEvent) {
    if (!this.ds || this.dialogOpen || this.imgDialog) {
      return;
    }

    if ((event.target['id'] != 'qty_plus' && this.validateEdited()) || (event.target['id'] != 'product_qty' && this.validateAllowUpdate())) {
      event.preventDefault();
      return;
    }

    if (event.target['id'] == 'input-autocomplete' || event.target['id'] == 'ean13') {
      return;
    }

    if (!this.isNumberOrLetter(event)) {
      return;
    }

    if ((event.target['id'] == 'product_qty' || event.target['id'] == 'qty_plus') && !this.onlyNumbers(event.key)) {
      event.preventDefault();
      return;
    }

    this.reload(1000);
  }

  reload(time) {
    window.clearTimeout(this.timer); // prevent errant multiple timeouts from being generated
    this.timer = window.setTimeout(() => {
      this.loadDataSource();
    }, time);
  }

  handleKeyDown(event: KeyboardEvent) {
    if (!this.ds || this.dialogOpen || this.imgDialog) {
      return;
    }

    if (this.allowUpdate && event.key == 'Escape') {
      this.qtyElement.nativeElement.blur();
      this.allowUpdate = false;
      this.setProduct(this.activeElement, true);
    }

    if ((event.target['id'] != 'qty_plus' && this.validateEdited()) || (event.target['id'] != 'product_qty' && this.validateAllowUpdate())) {
      event.preventDefault();
      return;
    }

    if (event.keyCode == this.ctrlKey || event.keyCode == this.cmdKey) {
      this.ctrlDown = true;
      return;
    }

    if (this.ctrlDown) {
      this.ctrlDown = false;
      return;
    }

    if (event.target['id'] == 'filter' || event.target['id'] == 'input-autocomplete' || event.target['id'] == 'ean13') {
      return;
    }

    if ((event.target['id'] == 'qty_plus' || event.target['id'] == 'product_qty') && !this.validateMinKeyBoardTime(event)) {
      event.preventDefault();
      return;
    }

    if (event.target['id'] == 'qty_plus') {
      if (event.key === 'Escape') {
        this.qtyPlusElement.nativeElement.blur();
        return;
      }

      if (event.key == 'ArrowUp' || event.key == 'ArrowDown' || event.key == 'Backspace') {
        this.edited = true;
        return;
      }

      if (event.key === 'Enter') {
        const first_date = new Date(this.date_update).getTime();
        const second_date = new Date().getTime();
        if ((Math.abs(first_date - second_date) / 1000) > 2) {
          this.updateQty();
        } else {
          this.alertService.show('Debes esperar unos segundos para volver a guardar');
          this.qtyPlusElement.nativeElement.focus();
          return;
        }

        this.date_update = new Date();
        this.qty_plus = 1;
        this.first_open = true;
        return;
      }

      if (!this.onlyNumbers(event.key)) {
        event.preventDefault();
        return;
      }

      const number_down = Number(event.key);
      if (this.first_open && number_down > 0) {
        setTimeout(() => { this.qty_plus = number_down; });
        this.first_open = false;
      }

      this.edited = true;
      return;
    }

    if (event.target['id'] == 'product_qty') {
      if (event.key === 'Enter') {
        this.saveQty(1);
      }

      if (event.key === 'Escape') {
        this.qtyElement.nativeElement.blur();
        this.allowUpdate = false;
        this.setProduct(this.activeElement, true);
      }

      if (event.key != 'ArrowUp' && event.key != 'ArrowDown' && event.key != 'Backspace' && !this.onlyNumbers(event.key)) {
        event.preventDefault();
      }

      return;
    }

    if (event.key === 'Backspace') {
      if (typeof this.filter != 'undefined') {
        if (this.filter.length > 0) {
          this.maxKeyBoardTime = event.timeStamp + 2000;
          this.filter = this.filter.slice(0, -1);
        } else {
          this.maxKeyBoardTime = undefined;
        }
        this.reload(1500);
      }
      return;
    }

    if (!this.isNumberOrLetter(event)) {
      return;
    }

    if (typeof this.filter == 'undefined' || typeof this.maxKeyBoardTime == 'undefined' || event.timeStamp > this.maxKeyBoardTime) {
      this.filter = '';
    }

    this.maxKeyBoardTime = event.timeStamp + 3000;
    this.filter += event.key;
  }

  isNumberOrLetter(event: KeyboardEvent) {
    var inp = String.fromCharCode(event.keyCode);
    return /[a-zA-Z0-9-_ ]/.test(inp) || ['-', '.', '/', '0'].includes(event.key);
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  getInfoInventory() {
    this.apiService.get(this.id).subscribe(result => {
      if (this.validateError(result, 'No se encuentran datos') == false) {
        return true;
      }

      this.inventory = result;
      if (this.inventory.state != 'draft') {
        this.startDataSource();
      }
    }, (error) => {
      this.apiService.validateError(error, " al obtener el inventario.");
    });
  }

  existInventoryInSql() {
    this.apiService.existInventoryInSql(this.id).subscribe(result => {
      if (result['error']) {
        return this.alertService.error(result['error']);
      }

      this.existInSql = result;
    }, (error) => {
      this.apiService.validateError(error, " al obtener validacion de inventario sql.");
    });
  }

  confirm() {
    if (this.id < 1) {
      return this.alertService.warn('No se puede validar');
    }

    this.alertService.show('Confirmando inventario...');
    this.apiService.confirm(this.id).subscribe(result => {
      if (this.validateError(result, 'Error al confirmar inventario. Quizás haya otro inventario abierto.') == false) {
        return true;
      }

      this.inventory.state = 'confirm';
      this.startDataSource();
      return this.alertService.success('Confirmado');
    }, (error) => {
      return this.apiService.validateError(error, " al confirmar el inventario.");
    });
  }

  startDataSource() {
    if (this.id < 1) {
      return this.alertService.warn('Error al buscar los productos por id incorrecto');
    }

    if (typeof this.ds == 'undefined') {
      this.ds = new MyDataSource(this.apiService, this.alertService, this.id);
    }
  }

  saveDataTable() {
    if (this.id < 1) {
      return this.alertService.warn('Error al buscar los productos por id incorrecto');
    }

    this.alertService.show('Guardando...');
    this.apiService.saveDataTable(this.id).subscribe(result => {
      if (this.validateError(result, 'Error al guardar datos en tabla SQL') == false) {
        return true;
      }

      this.startDataSource();
      this.filter = ' ';
      this.loadDataSource();
      this.existInventoryInSql();
    }, (error) => {
      return this.apiService.validateError(error, " guardar inventario en SQL.");
    });
  }

  validateError(data: any, error: any) {
    if (data['error']) {
      this.alertService.error(data['error']);
      return false;
    }

    if (!data) {
      this.alertService.error(error);
      return false;
    }

    return true;
  }

  loadDataSource() {
    this.ds.setFilters(this.filter, this.typeFilter);
    window.setTimeout(() => {
      if (this.ds.length == 1 && typeof this.ds.uniqueProduct != "undefined") {
        this.setProduct(this.ds.uniqueProduct);
      }

      if (!this.typeFilter.length && this.filter.length && this.ds.length == 0) {
        this.openDialog();
      }
    }, 1000);
  }

  setProduct(item: ProductSql, reload: boolean = false) {
    if (this.validateEdited() || this.validateAllowUpdate()) {
      return;
    }

    if (reload) {
      this.apiService.getProduct(item.id_inventory, item.id_inventory_line).subscribe(data => {
        if (data['error']) {
          return this.alertService.error(data['error']);
        }

        if (!data) {
          return this.alertService.error('Error al obtener los datos');
        }

        this.ds.uniqueProduct = data;
        this.activeElement = data;
      })
    }

    setTimeout(() => {
      if (this.activeElement != item) {
        this.activeElement = item;
        this.eanOriginal = item.product_ean13;
        this.qtyPreUpdate = item.product_qty;
        this.getProductLog();
        this.allowUpdate = false;
        this.qty_plus = 1;
        setTimeout(() => {
          this.first_open = true;
          this.edited = false;
          if (typeof this.qtyPlusElement !== 'undefined') {
            this.qtyPlusElement.nativeElement.focus();
          }
        }, 0);

      }
    }, 100);
  }

  getName(item: ProductSql) {
    return '( ' + item.product_ean13 + ' ) ' + item.product_name;
  }

  updateQty() {
    if (this.qty_plus == 0 || this.qty_plus == null) {
      this.edited = false;
      return;
    }

    if (this.activeElement.saved == 0) {
      this.activeElement.product_qty = 0;
    }

    if (typeof this.activeElement.product_qty == 'string') {
      this.activeElement.product_qty = Number(this.activeElement.product_qty);
    }

    if (typeof this.qty_plus == 'string') {
      this.qty_plus = Number(this.qty_plus);
    }

    this.saveQty();
  }

  saveQty(reset: number = 0) {
    let qty = Number(reset ? this.activeElement.product_qty : this.qty_plus);
    if (reset == 1 && qty == this.qtyPreUpdate) {
      this.allowUpdate = false;
      return;
    }

    this.apiService.saveQty(this.activeElement.id_inventory, this.activeElement.id_inventory_line, qty, this.activeElement.saved, reset).subscribe(result => {
      if (this.validateError(result, 'Error al guardar datos en tabla SQL') == false) {
        return true;
      }

      this.activeElement.saved = 1;
      this.allowUpdate = false;
      if (reset == 0) {
        this.activeElement.product_qty += qty;
        this.edited = false;
      }

      this.qtyPreUpdate = this.activeElement.product_qty;
      this.qty_plus = 1;
      this.getProductLog();
      return this.alertService.success('Se guarda la cantidad.');
    }, (error) => {
      return this.apiService.validateError(error, " al guardar la cantidad.");
    });
  }

  openDialog(): void {
    if (this.dialogOpen == false) {
      let dialogRef = this.dialog.open(NewProductComponent, {
        height: '500px',
        width: '1000px',
        data: { filter: this.filter, id_location: this.inventory.location_id.id, id_inventory: this.id }
      });

      this.dialogOpen = true;

      dialogRef.afterClosed().subscribe(result => {
        this.dialogOpen = false;
        if (typeof result != "undefined") {
          this.resetDataSource();
          setTimeout(() => {
            this.setProduct(result);
          }, 200);
        }
      });
    }
  }

  openImage(product_name: string, index: number) {
    if (typeof this.activeElement.images == 'undefined' || !this.activeElement.images.length) {
      return;
    }

    let dialogRef = this.dialog.open(ShowImageComponent, {
      width: '650px',
      data: { product_name: product_name, items: this.activeElement.images, lastIndex: index }
    });

    this.imgDialog = true;
    dialogRef.afterClosed().subscribe(result => { this.imgDialog = false });
  }

  updateEan() {
    if (!this.activeElement.product_ean13.length) {
      return this.alertService.warn('Ean incorrecto');
    }

    this.apiService.updateEan(this.activeElement.product_id, this.activeElement.product_ean13).subscribe(result => {
      if (this.validateError(result, 'Error al guardar ean') == false) {
        return true;
      }

      this.eanOriginal = this.activeElement.product_ean13;
      this.resetDataSource();
      return this.alertService.success('Se guarda ean.');
    }, (error) => {
      return this.apiService.validateError(error, " al guardar el ean.");
    });
  }

  resetDataSource() {
    this.ds = undefined;
    this.startDataSource();
    this.loadDataSource();
  }

  printEan() {
    if (this.activeElement.product_id < 1) {
      return this.alertService.error('No se encuentra id producto correcto.');
    }

    var url = 'https://www.orbitadigital.com/import/orders/manager/#/entradas/' + this.activeElement.product_id;
    window.open(url, "_blank");
  }

  isUpdated() {
    return this.activeElement.saved == 1 ? 'saved' : '';
  }

  getUrlProduct(element: ProductSql) {
    return 'https://erp.grupoconexion.com/web?db=Limpia#id=' + element.template_id + '&view_type=form&model=product.template&action=120';
  }

  validateInventory() {
    return this.router.navigate(['/actions/validate/' + this.id]);
  }

  updateInventory() {
    return this.router.navigate(['/actions/update/' + this.id]);
  }

  validateInventoryAdjustment() {
    return this.router.navigate(['/actions/adjustment/' + this.id]);
  }

  getProductLog() {
    this.apiService.getProductLog(this.activeElement.id_inventory, this.activeElement.id_inventory_line).subscribe(result => {
      if (this.validateError(result, 'Error al buscar el log de productos') == false) {
        return true;
      }

      this.logs = result;
    }, (error) => {
      return this.apiService.validateError(error, " al buscar el log de productos.");
    });
  }

  is_string(value) {
    return typeof value == 'string';
  }

  onlyNumbers(key): boolean {
    return /[0-9]/.test(key)
  }

  validateEdited() {
    if (!this.edited || this.qtyPlusElement.nativeElement.value == 1) {
      return false;
    }

    this.alertService.warn('No has guardado la cantidad introducida');
    return true;
  }

  validateSelectedItem(item) {
    if (typeof this.activeElement == 'undefined' || typeof item == 'undefined') {
      return false;
    }

    if (item.id_inventory == this.activeElement.id_inventory && item.id_inventory_line == this.activeElement.id_inventory_line) {
      return true
    }

    return false;
  }

  selectProductQuantity() {
    if (this.validateEdited()) {
      return;
    }

    this.allowUpdate = true;
    setTimeout(() => {
      this.qtyElement.nativeElement.click();
    }, 0);
  }

  validateAllowUpdate() {
    if (!this.allowUpdate) {
      return false;
    }

    if (this.qtyElement.nativeElement.value == this.qtyPreUpdate && this.qty_plus == 1) {
      return false;
    }

    this.alertService.warn('No has guardado la cantidad introducida');
    return true;
  }

  validateMinKeyBoardTime(event: Event): boolean {
    if (this.minKeyBoardTime == undefined) {
      this.minKeyBoardTime = event.timeStamp;
      return true;
    }

    if ((event.timeStamp - this.minKeyBoardTime) < 3) {
      this.minKeyBoardTime = event.timeStamp;
      setTimeout(() => {
        if (event.target['id'] == 'qty_plus') {
          this.qty_plus = 1;
        }

        if (event.target['id'] == 'product_qty') {
          this.activeElement.product_qty = this.qtyPreUpdate;
        }
      }, 0);
      return false;
    }

    this.minKeyBoardTime = event.timeStamp;
    return true;
  }

  goToOdooInventory() {
    window.open("https://erp.grupoconexion.com/web?debug=#id=" + this.id + "&view_type=form&model=stock.inventory&menu_id=269&action=314")
  }
}
