import { Component, EventEmitter, Output, OnChanges, Input, SimpleChanges } from '@angular/core';
import { MyDataSource } from './product-data-source.component';
import { ApiService } from 'src/app/services/api.service';
import { ProductItem } from '../types/product-item-model';
import { AlertService } from '../services/alert.service';

@Component({
    selector: 'app-list-products',
    templateUrl: './list-products.component.html',
    styleUrls: ['./list-products.component.scss'],
    host: {
        '(document:keydown)': 'handleKeyDown($event)'
    },
    standalone: false
})
export class ListProductsComponent implements OnChanges {
  @Output() valueChange = new EventEmitter();
  @Input() filter: string;
  @Input() type: string;
  @Input() brand: string;
  @Input() load: boolean;
  @Input() supplier: string;
  @Input() descatalogados: boolean;
  @Input() stock: any;
  ds;
  activeElement = undefined;
  lastIndex: number = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.type || changes.filter || changes.brand || changes.supplier || changes.load || changes.descatalogados || changes.stock) {
      if (this.filter == '') {
        // this.activeElement = undefined;
      }
      this.ds.setFilters(this.type, this.filter, this.brand, this.supplier, this.load, this.descatalogados, this.stock);
    }
  }

  constructor(private apiService: ApiService, private alertService: AlertService) {
    this.ds = new MyDataSource(this.apiService, this.alertService);
  }

  setProduct(item: ProductItem, i: number) {
    this.valueChange.emit(item);
    this.activeElement = item.id;
    this.lastIndex = i;
  }

  isParent(item: ProductItem) {
    if (typeof item != "undefined" && item.is_parent > 0) {
      return 'rgb(234, 255, 222)';
    }

    return '';
  }

  isActiveSelected(item) {
    if (typeof item == 'undefined') {
      return false;
    }

    return item.id === this.activeElement;
  }

  handleKeyDown($event) {
    if ($event.keyCode !== 9) {
      return;
    }

    if (typeof this.activeElement == 'undefined' || this.ds._cachedData.length == 1) {
      this.setProduct(this.ds._cachedData[0], 0);
      $event.preventDefault()
      return;
    }

    var x = false;
    for (let i = 0; i < this.ds._cachedData.length; i++) {
      if (x === true && typeof this.ds._cachedData[i] != 'undefined') {
        this.setProduct(this.ds._cachedData[i], i);
        break;
      } else if (typeof this.ds._cachedData[i] != 'undefined' && this.ds._cachedData[i].id == this.activeElement) {
        x = true;
      }
    }

    if (!x) {
      if (typeof this.ds._cachedData[this.lastIndex] == 'undefined') {
        for (let i = 0; i < this.ds._cachedData.length; i++) {
          if (typeof this.ds._cachedData[i] != 'undefined') {
            this.lastIndex = i;
          }
        }
      }

      this.setProduct(this.ds._cachedData[this.lastIndex], this.lastIndex);
    }

    $event.preventDefault();
  }
}
