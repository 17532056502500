<mat-toolbar color="secundary" class="app-toolbar">
  <mat-form-field class="filter-input" style="padding-top: 20px;">
    <input type="text" matInput [(ngModel)]="name" placeholder="Nombre inventario" autocomplete="off">
  </mat-form-field>
  <span class="app-toolbar-filler"></span>
  <mat-icon aria-hidden="false" aria-label="Proveedor" class="toolbar-icon" matTooltip="Ubicación inventariada">where_to_vote</mat-icon>
  <mat-form-field appearance="standard" class="form-field">
    <mat-label>Ubicación inventariada</mat-label>
    <mat-select [(ngModel)]="filter">
      <mat-option [value]="fil.value" *ngFor="let fil of filters">{{fil.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-icon aria-hidden="false" aria-label="Proveedor" class="toolbar-icon" matTooltip="Inventario de" style="padding-left: 20px;">assessment</mat-icon>
  <mat-form-field appearance="standard" class="form-field">
    <mat-label>Inventario de</mat-label>
    <mat-select [(ngModel)]="location">
      <mat-option [value]="loc.value" *ngFor="let loc of locations">{{loc.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <span class="app-toolbar-filler"></span>
  <button mat-raised-button (click)="add()" *ngIf="loaded">Crear</button>
</mat-toolbar>
<mat-sidenav-container class="app-sidenav-container">
  <mat-drawer-container class="drawer-container">
    <mat-progress-bar *ngIf="!loaded" mode="indeterminate"></mat-progress-bar>
  </mat-drawer-container>
</mat-sidenav-container>
