import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { Dto } from 'src/app/types/supplier-tarifa.model';

@Component({
    selector: 'app-suppliers-dto',
    templateUrl: './suppliers-dto.component.html',
    styleUrls: ['./suppliers-dto.component.scss'],
    standalone: false
})
export class SuppliersDtoComponent {
  public dto: Dto;

  constructor(private apiService: ApiService, private alertService: AlertService, public dialogRef: MatDialogRef<SuppliersDtoComponent, Dto>, @Inject(MAT_DIALOG_DATA) public data: Dto) {
    this.dto = data;

    if (this.dto.categ_id > 0) {
      this.apiService.searchOdoo("" + this.dto.categ_id, 'product.category').subscribe(s => {
        this.dto.categ = s.result[0];
      })
    }

    if (this.dto.id_supplier > 0) {
      this.apiService.searchOdoo("" + this.dto.id_supplier, 'supplier').subscribe(s => {
        this.dto.supplier_presta = s.result[0];
      })
    }
  }

  updateSearch(value: number, field: string) {
    this.dto[field] = value;

    if (value < 1) {
      return this.alertService.error('No se pudo actualizar el descuento.');
    }

    this.apiService.updateDto(this.dto.id, value, field).subscribe(result => {
      if (!result) {
        return this.alertService.error('No se pudo actualizar el ' + field);
      }

      this.dto[field] = value;
      return this.alertService.success('Descuento actualizado.');
    })
  }

  onNoClick(): void {
    this.dialogRef.close(this.dto);
  }
}
