import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { AlertService } from '../services/alert.service';
import { Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() dataSource: any = [];
  @Input() id_inventory: number;
  @Input() type: string;
  public displayedColumns: string[] = ['id_line', 'id_product', 'name', 'result'];
  public options: any[] = [
    { name: 'Ver todo', value: '' },
    { name: 'Sin stock', value: '#fcf6cc' },
    { name: 'Revisar', value: '#ffebeb' },
  ];
  public filter: any;
  public showFilters: boolean = true;

  constructor(private apiService: ApiService, private alertService: AlertService, private router: Router) {
  }

  ngOnInit(): void {
  }

  updateDataOdoo() {
    this.dataSource = undefined;
    this.apiService.updateData(this.id_inventory, true).subscribe(result => {
      if (result['error']) {
        return this.alertService.error(result['error']);
      }

      this.dataSource = [...result];
    }, (error) => {
      return this.apiService.validateError(error, " al actualizar los productos del inventario odoo.");
    });
  }

  getOptions() {
    this.options = [];
    switch (this.type) {
      case 'validate':
        this.options = [{ option: 'hola', value: '' }];
        break;
      case 'update':
        this.options = [{ option: 'hola', value: '' }];
        break;
      case 'adjustment':
        this.options = [{ option: 'hola', value: '' }];
        break;
    }
  }

  getColorTr(row) {
    if (typeof row.product_qty == 'undefined' || typeof row.theoretical_qty == 'undefined') {
      this.showFilters = false;
      return '';
    }

    if (row.product_qty == row.theoretical_qty) {
      return '';
    }

    if (row.product_qty == 0) {
      return '#fcf6cc'
    }

    let error = parseInt(row.theoretical_qty);
    error *= 0.35;
    Math.floor(error);

    let maxError = row.theoretical_qty - row.product_qty;

    if (maxError < 0) {
      maxError *= -1;
    }

    if (maxError >= error) {
      return '#ffebeb';
    }

    return '';
  }

  returnToPage() {
    this.router.navigate(['products/' + this.id_inventory]);
  }

  validateHidden(row) {
    if (typeof row.show == 'undefined') {
      return false;
    }

    return row.show;
  }

  updateFilter(event: MatSelectChange) {
    this.dataSource.forEach(element => {
      if (event.value.length) {
        if (this.getColorTr(element) == event.value) {
          element.show = false;
        } else {
          element.show = true;
        }
      } else {
        element.show = false;
      }
    });
  }
}
