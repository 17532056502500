import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { SearchOdoo } from 'src/app/types/search-odoo.model';

@Component({
    selector: 'app-search-odoo',
    templateUrl: './search-odoo.component.html',
    styleUrls: ['./search-odoo.component.scss'],
    standalone: false
})
export class SearchOdooComponent implements OnInit, OnChanges {
  public form: UntypedFormGroup;
  public toHighlight: string = '';
  public isLoadingProducts = false;
  public data: SearchOdoo[];

  @Output() valueChange: EventEmitter<number> = new EventEmitter();
  @Input() search_type: string;
  @Input() placeholder: string;
  @Input() rel: string;
  @Input() value_selected: { id: number, name: string };

  constructor(private apiService: ApiService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      autocompleteSearch: new UntypedFormControl()
    });

    this.resetAutocomplete();

    this.form
      .get('autocompleteSearch')
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoadingProducts = true),
        switchMap(value => this.apiService.searchOdoo(value, this.search_type)
          .pipe(
            finalize(() => this.isLoadingProducts = false),
          )
        )
      ).subscribe((data) => {
        if (!data.result.length) {
          data.result.push({
            id: 0,
            name: 'No se encuentran datos',
          })
        }

        this.data = data.result;
        this.toHighlight = data.search;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value_selected && changes.value_selected.firstChange == false) {
      this.form.setValue({ autocompleteSearch: this.value_selected }, { onlySelf: true, emitEvent: false });
    }
  }

  displayName(searchResult: any) {
    if (searchResult) {
      return searchResult.name;
    }

    return '';
  }

  resetAutocomplete() {
    this.form.setValue({
      autocompleteSearch: ''
    });
  }

  onSelect(event: MatAutocompleteSelectedEvent) {
    if (event.option) {
      this.valueChange.emit(event.option.value.id);
    }
  }
}
