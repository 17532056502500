import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { Inventory } from 'src/app/types/inventory.model';
import { Product } from 'src/app/types/product.model';
import { Autocomplete } from 'src/app/types/productSql.model';
import { ProductSql } from 'src/app/types/productSql.model';
import { ProductLog } from '../types/productLog.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private API_URL = window.location.pathname.replace('/manager/', '/api.php');

  constructor(private http: HttpClient, private alertService: AlertService) {
    if (!environment.production) { // isDevMode()
      this.API_URL = window.location.hostname.includes('localhost') ? 'http://localhost/prestashop' : 'https://dm.orbitadigital.es';
      this.API_URL += '/import/inventario/api.php';
    }

    console.log(this.API_URL);
  }

  public validateError(error, message) {
    console.log(error);

    if (typeof error.error != "undefined") {
      this.alertService.error(error.error.text);
    }

    if (typeof error.status == "undefined" || error.status == 200) {
      return true;
    }

    if (error.status == 0) {
      error.status = '';
    }

    this.alertService.error("ERROR " + error.status + message);
  }

  public add(name: string, filter: string, location: number) {
    return this.http.get<any>(this.API_URL, {
      params: {
        action: 'add',
        name: encodeURIComponent(name),
        filter: encodeURIComponent(filter),
        location: `${location}`
      },
      responseType: 'json'
    });
  }

  public existInventoryInSql(id: number) {
    return this.http.get<any>(this.API_URL, {
      params: {
        action: 'existInventoryInSql',
        id: `${id}`
      },
      responseType: 'json'
    });
  }

  public get(id: number) {
    return this.http.get<Inventory>(this.API_URL, {
      params: {
        action: 'get',
        id: `${id}`
      },
      responseType: 'json'
    });
  }

  public confirm(id: number) {
    return this.http.get<boolean>(this.API_URL, {
      params: {
        action: 'confirm',
        id: `${id}`
      },
      responseType: 'json'
    });
  }

  public saveDataTable(id: number) {
    return this.http.get<Product[]>(this.API_URL, {
      params: {
        action: 'saveDataTable',
        id: `${id}`
      },
      responseType: 'json'
    });
  }

  public loadDataSource(id: number, limit: number, offset: number, filter?: string, typeFilter?: string) {
    return this.http.get<any>(this.API_URL, {
      params: {
        action: 'loadDataSql',
        id: `${id}`,
        limit: `${limit}`,
        offset: `${offset}`,
        filter: encodeURIComponent(filter),
        typeFilter: encodeURIComponent(typeFilter),
      },
      responseType: 'json'
    });
  }

  public searchImage(product_code: string) {
    return this.http.get<string[]>(this.API_URL, {
      params: {
        action: 'searchImage',
        product_code: encodeURIComponent(product_code),
      },
      responseType: 'json'
    });
  }

  public saveQty(id_inventory: number, id_inventory_line: number, product_qty: number, saved: number, reset: number) {
    return this.http.get<string>(this.API_URL, {
      params: {
        action: 'saveQty',
        id_inventory: `${id_inventory}`,
        id_inventory_line: `${id_inventory_line}`,
        product_qty: `${product_qty}`,
        saved: `${saved}`,
        reset: `${reset}`,
      },
      responseType: 'json'
    });
  }

  public searchNewProducts(filter: string, id_location: number) {
    filter = filter.trim()
    if (filter.length == 0) {
      return of({} as Autocomplete);
    }

    return this.http.get<Autocomplete>(this.API_URL, {
      params: {
        action: 'searchNewProducts',
        filter: encodeURIComponent(filter),
        id_location: `${id_location}`
      },
      responseType: 'json'
    });
  }

  public createNewLine(data: ProductSql, id_inventory: number) {
    const body = JSON.stringify(data);
    return this.http.post<any>(this.API_URL, body, {
      params: {
        action: 'createNewLine',
        id_inventory: `${id_inventory}`
      },
      responseType: 'json'
    });
  }

  public updateData(id: number, write: boolean) {
    return this.http.get<any>(this.API_URL, {
      params: {
        action: 'updateData',
        id: `${id}`,
        write: `${write}`
      },
      responseType: 'json'
    });
  }

  public validateData(id: number) {
    return this.http.get<any>(this.API_URL, {
      params: {
        action: 'validateData',
        id: `${id}`
      },
      responseType: 'json'
    });
  }

  public validateInventoryAdjustment(id: number) {
    return this.http.get<any>(this.API_URL, {
      params: {
        action: 'validateInventoryAdjustment',
        id: `${id}`
      },
      responseType: 'json'
    });
  }

  public updateEan(id: number, ean13: string) {
    return this.http.get<any>(this.API_URL, {
      params: {
        action: 'updateEan',
        id: `${id}`,
        ean13: encodeURIComponent(ean13)
      },
      responseType: 'json'
    });
  }

  public getProductLog(id_inventory: number, id_inventory_line: number) {
    return this.http.get<ProductLog[]>(this.API_URL, {
      params: {
        action: 'getProductLog',
        id_inventory: `${id_inventory}`,
        id_inventory_line: `${id_inventory_line}`,
      },
      responseType: 'json'
    });
  }

  public getProduct(id_inventory: number, id_inventory_line: number) {
    return this.http.get<ProductSql>(this.API_URL, {
      params: {
        action: 'getProduct',
        id_inventory: `${id_inventory}`,
        id_inventory_line: `${id_inventory_line}`,
      },
      responseType: 'json'
    });
  }
};
