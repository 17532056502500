import { RouterModule, Routes } from '@angular/router';
import { ActionsComponent } from './actions/actions.component';
import { CreateComponent } from './create/create.component';
import { ProductsComponent } from './products/products.component';

const APP_ROUTES: Routes = [
  { path: 'create', component: CreateComponent },
  { path: 'actions/:type/:id', component: ActionsComponent },
  { path: 'products/:id', component: ProductsComponent },
  { path: 'products', redirectTo: 'create', pathMatch: 'full' },
  { path: 'actions', redirectTo: 'create', pathMatch: 'full' },
  { path: 'actions/:type', redirectTo: 'create', pathMatch: 'full' },
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);
