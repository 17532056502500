<mat-accordion>
  <mat-expansion-panel hideToggle *ngFor="let label of labels" [expanded]="false">
    <mat-expansion-panel-header>
      <mat-panel-title class="title">{{ label.panel_title }}</mat-panel-title>
    </mat-expansion-panel-header>

    <mat-expansion-panel hideToggle *ngFor="let sublabel of label.panel" [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title class="sub-title">{{ sublabel.panel_title }}</mat-panel-title>
      </mat-expansion-panel-header>
      <div [innerHtml]="sublabel.panel_text | safeHtml"></div>
    </mat-expansion-panel>
  </mat-expansion-panel>
</mat-accordion>
