
<mat-toolbar color="primary" class="app-toolbar">
  <button mat-icon-button (click)="sidenav.toggle()">
    <mat-icon><span class="material-icons">menu</span></mat-icon>
  </button>
  <h1 class="app-name">{{app_name}}</h1>
</mat-toolbar>

<mat-sidenav-container class="app-sidenav-container">
  <mat-sidenav #sidenav [mode]="mobileQuery.matches ? 'push' : 'push'" [fixedInViewport]="true" [fixedTopGap]="mobileQuery.matches ? '56' : '64'">
    <div *ngIf="show && !isLoading">
      <mat-nav-list>
          <a mat-list-item routerLink="search">Productos</a>
      </mat-nav-list>
      <mat-nav-list>
        <a mat-list-item routerLink="prices">Precios</a>
      </mat-nav-list>
      <mat-nav-list *ngIf="isPro">
          <a mat-list-item routerLink="logs">Logs</a>
      </mat-nav-list>
      <mat-nav-list *ngIf="isPro">
          <a mat-list-item routerLink="control">Registros</a>
      </mat-nav-list>
      <mat-nav-list *ngIf="isPro">
          <a mat-list-item routerLink="brands">Marcas</a>
      </mat-nav-list>
      <mat-nav-list *ngIf="isPro">
          <a mat-list-item routerLink="rules">Reglas</a>
      </mat-nav-list>
      <mat-nav-list *ngIf="isPro">
          <a mat-list-item routerLink="suppliers">Proveedores</a>
      </mat-nav-list>
      <mat-nav-list *ngIf="isPro">
          <a mat-list-item routerLink="parents">Productos padre</a>
      </mat-nav-list>
      <mat-nav-list *ngIf="isPro">
          <a mat-list-item routerLink="new-brands">Marcas de proveedores</a>
      </mat-nav-list>
      <mat-nav-list>
          <a mat-list-item routerLink="check">Revisar</a>
      </mat-nav-list>
      <mat-nav-list>
          <a mat-list-item routerLink="exceptions">Excepciones</a>
      </mat-nav-list>
      <mat-nav-list>
          <a mat-list-item routerLink="odoo-products">Productos odoo</a>
      </mat-nav-list>
      <mat-nav-list>
          <a mat-list-item (click)="openSite('https://www.orbitadigital.com/import/icecat/manager/#/search', '_self')">Icecat</a>
      </mat-nav-list>
      <mat-nav-list *ngIf="isPro">
          <a mat-list-item (click)="openSite('https://www.orbitadigital.com/modules/prestaodoo/manager/#/info')">Prestaodoo</a>
      </mat-nav-list>
      <mat-nav-list *ngIf="isPro">
        <a mat-list-item routerLink="config">Configuración</a>
      </mat-nav-list>
      <mat-nav-list *ngIf="isPro">
        <a mat-list-item routerLink="employees">Control</a>
      </mat-nav-list>
      <mat-nav-list>
        <a mat-list-item routerLink="tarifa">Tarifa</a>
      </mat-nav-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="app-content">
      <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
