<h2 mat-dialog-title>{{ data.id > 0 ? 'Editar archivo:' + data.file : 'Añadir archivo'}}</h2>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Prefijo de la referencia</mat-label>
    <input matInput [(ngModel)]="data.prefix">
  </mat-form-field>

  <div *ngIf="data.id < 1" id="add-file">
    <label>Seleccionar archivo</label>
    <div>
      <input type="file" (change)="selectFile($event)" accept=".csv">
    </div>
  </div>

  <mat-form-field>
    <mat-label>Descuento (%)</mat-label>
    <input matInput [(ngModel)]="data.dto">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Delimitador CSV (, o ;)</mat-label>
    <input matInput [(ngModel)]="data.delimiter">
  </mat-form-field>

  <mat-slide-toggle [checked]="getBool(data.update_ean)" (change)="data.update_ean = $event.checked">Actualizar ean</mat-slide-toggle>
  <mat-slide-toggle [checked]="getBool(data.update_whosale_price)" (change)="data.update_whosale_price = $event.checked">Actualizar precio de compra</mat-slide-toggle>
  <mat-slide-toggle [checked]="getBool(data.update_price)" (change)="data.update_price = $event.checked">Actualizar precio de venta</mat-slide-toggle>
  <mat-slide-toggle [checked]="getBool(data.update_category)" (change)="data.update_category = $event.checked">Actualizar proveedor de prestashop o categoría de odoo</mat-slide-toggle>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cerrar</button>
  <button mat-button (click)="addNew()" cdkFocusInitial *ngIf="data.id < 1">Crear</button>
  <button mat-button (click)="update()" cdkFocusInitial *ngIf="data.id > 0">Actualizar</button>
</div>
