import { Component, OnInit } from '@angular/core';
import { ProductItem, SearchProducts } from 'src/app/types/product-item-model';
import { ApiService } from '../services/api.service';
import { AlertService } from '../services/alert.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    standalone: false
})
export class SearchComponent implements OnInit {
  public filter: string = '';
  public search: SearchProducts;
  public itemSelected: ProductItem;
  public type: string = '0';
  public brand: string = '';
  public toHighlight: string = '';
  public supplier: string;
  public load: boolean;
  public descatalogados: boolean = false;
  public stock: boolean = true;

  constructor(private apiService: ApiService, private alertService: AlertService) {
    this.type = '0';
    this.supplier = 'undefined';
    this.load = true;
    this.brand = '';
  }

  ngOnInit(): void { }

  productSelect(product: ProductItem) {
    this.itemSelected = product;
  }

  onSelectBrand(event) {
    this.brand = event;
  }

  clear() {
    this.load = false;

    setTimeout(() => {
      this.load = true;
    }, 60);
  }

  addToParents() {
    if (typeof this.type == 'undefined' || this.type != '0') {
      return this.alertService.warn('Accion no permitida.');
    }

    if (typeof this.brand == 'undefined' || !this.brand.length) {
      return this.alertService.warn('No se selecciona filtro de marca.');
    }

    this.apiService.addToParents(this.filter, this.type, this.brand, this.supplier).subscribe(result => {
      if (result.length) {
        return this.alertService.error(result[0]);
      }

      this.clear();

      return this.alertService.success('Se actualizaron los productos.');
    });
  }

  changeSupplier(supplier: string) {
    if (this.supplier == supplier) {
      return;
    }
    this.supplier = supplier;
  }

  newSearchProduct(element: ProductItem) {
    this.itemSelected = element;
  }
}
