import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { Neto } from 'src/app/types/supplier-tarifa.model';

@Component({
    selector: 'app-netos',
    templateUrl: './netos.component.html',
    styleUrls: ['./netos.component.scss'],
    standalone: false
})
export class NetosComponent {
  neto: Neto;

  constructor(private apiService: ApiService, private alertService: AlertService, public dialogRef: MatDialogRef<NetosComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.apiService.getNeto(this.data.id_file, this.data.reference).subscribe(r => {
      if (r['error']) {
        return this.alertService.error(r['error']);
      }

      if (Array.isArray(r) && !r.length) {
        r = {
          date_add: '',
          date_upd: '',
          id_file: this.data.id_file,
          reference: this.data.reference,
          neto_wholesale_price: 0,
          neto_price: 0,
          id_supplier: 0,
          categ_id: 0,
        }
      }

      this.neto = r;
    })
  }

  updateSearch(value: number, field: string) {
    this.neto[field] = value;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addNew() {
    this.apiService.saveNeto(this.neto).subscribe(r => {
      if (r['error']) {
        return this.alertService.error(r['error']);
      }

      this.dialogRef.close(true);
    })
  }
}
