import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { SupplierTarifa } from 'src/app/types/supplier-tarifa.model';

@Component({
    selector: 'app-supplier',
    templateUrl: './supplier.component.html',
    styleUrls: ['./supplier.component.scss'],
    standalone: false
})
export class SupplierComponent {
  constructor(private apiService: ApiService, private alertService: AlertService, public dialogRef: MatDialogRef<SupplierComponent>, @Inject(MAT_DIALOG_DATA) public data: SupplierTarifa) {
    if (this.data.categ_id > 0) {
      this.apiService.searchOdoo("" + this.data.categ_id, 'product.category').subscribe(s => {
        this.data.categ = s.result[0];
      })
    }

    if (this.data.brand_id > 0) {
      this.apiService.searchOdoo("" + this.data.brand_id, 'product.brand').subscribe(s => {
        this.data.brand = s.result[0];
      })
    }

    if (this.data.supplier_id > 0) {
      this.apiService.searchOdoo("" + this.data.supplier_id, 'res.partner').subscribe(s => {
        this.data.supplier = s.result[0];
      })
    }

    if (this.data.id_supplier > 0) {
      this.apiService.searchOdoo("" + this.data.id_supplier, 'supplier').subscribe(s => {
        this.data.supplier_presta = s.result[0];
      })
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateSearch(value: number, field: string) {
    this.data[field] = value;
  }

  addNew() {
    this.apiService.addSupplierTarifa(this.data).subscribe(r => {
      if (r['errors']) {
        return this.alertService.error(r['errors'][0]);
      }

      if (!r['result']) {
        return this.alertService.error("No se pudo crear.");
      }

      this.data.id = r['result'];
      this.dialogRef.close(this.data);
    })
  }

  update() {
    this.apiService.updateSupplierTarifa(this.data).subscribe(r => {
      if (r['error']) {
        return this.alertService.error(r['error']);
      }

      if (!r['result']) {
        return this.alertService.error("No se pudo actualizar.");
      }

      return this.alertService.success("Se actualiza.");
    })
  }

}
