<h1 mat-dialog-title>{{data.column}}</h1>
<div mat-dialog-content>
  <mat-form-field *ngIf="header" class="hide-hint">
    <mat-label>Renombrar header</mat-label>
    <mat-select (selectionChange)="updateNewHeader($event)" [value]="getNewHeader()">
      <mat-option *ngFor="let option of options" [value]="option">{{option}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cerrar</button>
  <button mat-button (click)="addNew()" cdkFocusInitial *ngIf="header && !isUpdated()">Crear</button>
  <button mat-button (click)="updateHeader()" cdkFocusInitial *ngIf="header && isUpdated()">Actualizar</button>
  <button mat-icon-button *ngIf="header && isUpdated()" (click)="deleteHeader()" [matTooltip]="'Eliminar'"><mat-icon>delete</mat-icon></button>
</div>
