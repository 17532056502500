<mat-toolbar color="secundary" class="app-toolbar">

  <mat-icon aria-hidden="false" aria-label="Type" [matTooltip]="'Añadir proveedor'" class="toolbar-icon" class="toolbar-icon" (click)="openSupplier(true)">add_box</mat-icon>
  <mat-form-field appearance="fill" class="small-form-field hide-hint">
    <mat-label>Proveedores</mat-label>
    <mat-select (selectionChange)="changeSupplier($event)" [value]="supplier">
      <mat-option *ngFor="let option of suppliers" [value]="option">{{option.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-icon *ngIf="supplier && supplier.id > 0" aria-hidden="false" aria-label="Type" [matTooltip]="'Editar proveedor'" class="toolbar-icon" class="toolbar-icon" (click)="openSupplier(false)">update</mat-icon>

  <span class="app-toolbar-filler"></span>
  
  <mat-icon aria-hidden="false" aria-label="Type" [matTooltip]="'Añadir archivo'" class="toolbar-icon" (click)="openTarifa(true)" *ngIf="supplier">my_library_add</mat-icon>
  <mat-form-field appearance="fill" class="small-form-field hide-hint" *ngIf="supplier && supplier.id > 0">
    <mat-label>Archivos</mat-label>
    <mat-select (selectionChange)="changeFile($event)" [value]="file">
      <mat-option *ngFor="let option of files" [value]="option">{{option.file}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-icon *ngIf="supplier && file && file.id > 0" aria-hidden="false" aria-label="Type" [matTooltip]="'Editar archivo'" class="toolbar-icon" (click)="openTarifa(false)">system_update_alt</mat-icon>

  <span class="app-toolbar-filler"></span>

  <mat-form-field appearance="fill" class="small-form-field hide-hint" *ngIf="supplier && supplier.id > 0 && data">
    <mat-label>Tipo de busqueda</mat-label>
    <mat-select (selectionChange)="changeType($event)" [value]="type">
      <mat-option value="odoo">Odoo</mat-option>
      <mat-option value="prestashop">Prestashop</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-icon (click)="openDtos()" *ngIf="supplier && supplier.id > 0 && data" [matTooltip]="'Abrir descuentos'">monetization_on</mat-icon>
  
  <span class="app-toolbar-filler"></span>
  <mat-form-field appearance="fill" class="small-form-field hide-hint" *ngIf="header">
    <mat-label>Renombrar columnas</mat-label>
    <mat-select>
      <mat-option *ngFor="let column of header_to_rename; let i = index" (click)="openColumn(i, column)"> {{column}} </mat-option>
    </mat-select>
  </mat-form-field>

  <span class="app-toolbar-filler"></span>
  <mat-form-field appearance="fill" class="small-form-field hide-hint" *ngIf="data && dataSource">
    <mat-label>Busqueda</mat-label>
    <input matInput [formControl]="filter">
  </mat-form-field>

  <span class="app-toolbar-filler"></span>
  <mat-icon class="margin-right" (click)="updateTarifa()" *ngIf="data && dataSource && !isLoading" [matTooltip]="'Actualizar datos en ' + type">import_export</mat-icon>
  <mat-icon class="margin-right" (click)="getDataTarifa()" *ngIf="data && dataSource && !isLoading" [matTooltip]="'Actualizar tabla'">loop</mat-icon>
  <div class="info" cdkDrag [style.display]="!openInfoData ? 'none': ''">
    <app-info></app-info>
    <div class="info-handle">
      <mat-icon (click)="openInfo()">close</mat-icon>
    </div>
  </div>
  <mat-icon (click)="openInfo()" [matTooltip]="'Información'">live_help</mat-icon>
</mat-toolbar>
<mat-sidenav-container class="app-sidenav-container">
  <mat-drawer-container class="drawer-container">
    <mat-spinner class="spinner-load" *ngIf="isLoading"></mat-spinner>

    <table *ngIf="data && dataSource && !isLoading" mat-table matSort [dataSource]="dataSource" (matSortChange)="sortData()">
      <ng-container [matColumnDef]="column" *ngFor="let column of header; let i = index">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column == 'actions' ? '' : getNameColumn(column) | titlecase }} </th>
        <td mat-cell *matCellDef="let element"> 
          <div [ngSwitch]="column">
            <div class="btn-edit" *ngSwitchCase="'actions'" >
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="addNeto(element)"><span>Añadir neto</span></button>
                <button mat-menu-item (click)="deleteNeto(element)"><span>Eliminar neto</span></button>
              </mat-menu>
            </div>
            <span *ngSwitchDefault [innerHTML]="element[column]">
            </span>
          </div>
        </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="header; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: header;" [style.background-color]="row.color"></tr>
    </table>
    <mat-paginator *ngIf="data && dataSource && !isLoading" [pageSizeOptions]="[10, 15, 20, 25, 50, 100]" showFirstLastButtons [length]="data.length" pageSize="20"></mat-paginator>
  </mat-drawer-container>
</mat-sidenav-container>
