<div *ngFor="let dto of dtos" style="display: flex;"> 
    <mat-form-field class="dto_value">
        <mat-label>Grupo de descuentos</mat-label>
        <input type="text" matInput [(ngModel)]="dto.value" readonly>
    </mat-form-field>
    <span class="app-toolbar-filler"></span>

    <mat-form-field class="example-full-width">
        <mat-label>Descuento</mat-label>
        <input type="number" matInput [(ngModel)]="dto.dto">
    </mat-form-field>
    <span class="app-toolbar-filler"></span>

    <button mat-icon-button *ngIf="dto.id > 0" (click)="openSuppliers(dto)" [matTooltip]="'Añadir proveedores'"><mat-icon>local_offer</mat-icon></button>
    <button mat-icon-button (click)="dto.id > 0 ? updateDto(dto) : saveDto(dto)" [matTooltip]="'Guardar'"><mat-icon>save</mat-icon></button>
    <button mat-icon-button *ngIf="dto.id > 0" (click)="deleteDto(dto)" [matTooltip]="'Eliminar'"><mat-icon>delete</mat-icon></button>
</div>
