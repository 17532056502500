<h1 mat-dialog-title>{{ product_name }}</h1>
<div mat-dialog-content>
  <div class="wrap" [style.height]="'580px'" *ngIf="items">
    <div [style.line-height]="'580px'" class="carousel_prev-button" (click)="prev()">
      <a class="carousel-control-prev-icon"></a>
    </div>
    <div class="item">
      <img [src]="getImage()" alt="Image selected">
    </div>
    <div [style.line-height]="'580px'" class="carousel_next-button" (click)="next()">
      <a class="carousel-control-next-icon"></a>
    </div>
  </div>
  <ng-container *ngIf="items">
    <div class="selectors">
        <img *ngFor="let item of items; let i = index" (click)="setSlide(i)" [class]="i == lastIndex ? 'img_selected' : ''" [src]="item"/>
    </div>
  </ng-container>
</div>
