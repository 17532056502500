import { Component, OnInit } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { SupplierBrand } from '../types/supplier-brand.model';
import { Supplier } from '../types/supplier.model';

@Component({
    selector: 'app-supplier-brands',
    templateUrl: './supplier-brands.component.html',
    styleUrls: ['./supplier-brands.component.scss'],
    standalone: false
})
export class SupplierBrandsComponent implements OnInit {
  public dataSource: SupplierBrand[] = [];
  public suppliers: Supplier[] = [];
  public supplier: string = '';
  public displayedColumns: string[] = ['brand', 'action'];
  public isLoading = false;
  public dataSchema = {
    'brand': 'string',
    'action': 'string'
  };
  public translate = {
    'brand': 'Marca',
    'action': 'Accion'
  };

  constructor(private apiService: ApiService, private alertService: AlertService) {
    this.getSuppliers();
  }

  ngOnInit(): void {
  }

  changeSupplier(event: string) {
    if (this.supplier == event) {
      return;
    }

    this.supplier = event;
    this.getData();
  }

  getData() {
    if (!this.supplier.length) {
      return;
    }

    this.isLoading = true;
    this.apiService.getBrandsSuppliers(this.supplier).subscribe(data => {
      this.isLoading = false;

      if (data['error']) {
        return this.alertService.error(data['error']);
      }

      this.dataSource = [...data];
    });
  }

  getSuppliers() {
    this.apiService.getSuppliers().subscribe(data => {
      if (data['error']) {
        return this.alertService.error(data['error']);
      }

      if (!data) {
        return this.alertService.error('Error al obtener proveedores de la base de datos');
      }

      this.suppliers = [...data];
    });
  }

  action(element: SupplierBrand) {
    var action = element.action;

    if (action == 'add_box') {
      action = 'add';
    }

    this.apiService.actionBrandSupplier(this.supplier, element.brand, action).subscribe(data => {
      if (data['error']) {
        return this.alertService.error(data['error']);
      }

      this.getData();
    });

  }
}
