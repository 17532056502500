import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-image',
  templateUrl: './show-image.component.html',
  styleUrls: ['./show-image.component.scss']
})
export class ShowImageComponent {
  public items: string[] = [];
  public product_name: string = '';
  public lastIndex: number = 1;

  constructor(public dialogRef: MatDialogRef<ShowImageComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.product_name = data.product_name;
    this.items = data.items;
    this.lastIndex = data.lastIndex;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  prev() {
    if (typeof this.items[this.lastIndex - 1] == 'undefined') {
      return;
    }

    this.lastIndex -= 1;
  }

  next() {
    if (typeof this.items[this.lastIndex + 1] == 'undefined') {
      return;
    }

    this.lastIndex += 1;
  }

  setSlide(slide: number) {
    if (typeof this.items[slide] == 'undefined') {
      return;
    }

    this.lastIndex = slide;
  }

  public getImage() {
    return this.items[this.lastIndex].replace('-medium_default', '-large_default') ?? '';
  }
}
