import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, Observable, fromEvent, merge, of } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { ApiService } from '../services/api.service';
import { catchError, debounceTime, distinctUntilChanged, finalize, tap } from 'rxjs/operators';
import { ControlEmployee, Employee } from '../types/control-employee.model';
import { AlertService } from '../services/alert.service';
import { LoadData } from '../types/load-data-model';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-control-employees',
    templateUrl: './control-employees.component.html',
    styleUrls: ['./control-employees.component.scss'],
    standalone: false
})

export class ControlEmployeesComponent implements AfterViewInit {
  displayedColumns: string[] = ['id_employee', 'project', 'action', 'query', 'date'];
  dataSource: EmployeeDataSource;
  employees: Employee[] = [];
  employeeId: number = 0;
  projects: string[] = [];
  projectOption: string = "";
  checked!: boolean;
  date = new UntypedFormControl({ value: null, disabled: true });

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('filter') filter!: ElementRef;

  constructor(private apiService: ApiService, private alertService: AlertService) {
    this.dataSource = new EmployeeDataSource(this.apiService, this.alertService);
    this.dataSource.load(0, '', 'asc', 0);

    this.apiService.getAllEmployees().subscribe(res => {
      this.employees = res;
    });

    this.apiService.getAllProjects().subscribe(res => {
      this.projects = res;
    });
  }

  ngAfterViewInit() {
    fromEvent(this.filter.nativeElement, 'keyup')
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.getData();
        })
      )
      .subscribe();


    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.getData())
      )
      .subscribe();
  }

  getData() {
    this.dataSource.load(
      this.employeeId,
      this.filter.nativeElement.value,
      this.sort.direction,
      this.paginator.pageIndex,
      this.sort.active,
      this.projectOption,
      this.checked,
      this.date.value != null ? this.date.value.toISOString() : '');
  }

  changeEmployee(event: any) {
    this.employeeId = event.value;
    this.getData();
  }

  changeProject(value: any) {
    this.projectOption = value;
    this.getData();
  }

  changeDelete(event: any) {
    this.checked = event.checked
    this.getData()
  }

  clearFilter() {
    this.filter.nativeElement.value = "";
    this.paginator.pageIndex = 0;
    this.getData();
  }

  existFilter() {
    if (typeof this.filter == "undefined") {
      return false;
    }

    if (this.filter.nativeElement.value.length) {
      return true;
    }

    return false;
  }

  clearDate() {
    this.date.setValue(null);
    this.getData();
  }
}

export class EmployeeDataSource implements DataSource<ControlEmployee> {
  private dataSubject = new BehaviorSubject<ControlEmployee[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public total = 20;
  public _pageSize = Math.ceil(((window.screen.height || 1000) - 64 - 65) / 60);

  constructor(private apiService: ApiService, private alertService: AlertService) { }

  connect(collectionViewer: CollectionViewer): Observable<ControlEmployee[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  async load(employeeId: number = 0, filter: string = '', orderWay: string = 'asc', page: number = 0, orderBy: string = 'id', project: string = "", deleted: boolean = false, date: string = "") {
    this.loadingSubject.next(true);

    const offset = page * this._pageSize;
    const limit = offset + this._pageSize;

    this.apiService.getDataControlEmployees(employeeId, filter, orderWay, offset, limit, orderBy, project, deleted, date).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe((data: LoadData<ControlEmployee>) => {
        if (data['error']) {
          return this.alertService.error(data['error']);
        }

        this.total = data.total;
        this.dataSubject.next(data.result)
      });
  }
}
