<mat-toolbar color="secundary" class="app-toolbar">
  <mat-form-field class="filter-input" style="padding-top: 20px;" *ngIf="ds">
    <input type="text" id="filter" matInput [ngModel]="filter" placeholder="Buscar producto por referencia o ean" autocomplete="off" disabled>
    <button *ngIf="filter" matSuffix mat-icon-button aria-label="Clear" (click)="filter=''; loadDataSource()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <span class="app-toolbar-filler" *ngIf="ds && existInSql"></span>
  <mat-form-field id="typeFilter" *ngIf="ds && existInSql">
    <mat-label>Filtro de busqueda</mat-label>
    <mat-select [(value)]="typeFilter" (selectionChange)="loadDataSource()" placeholder="Filtro de busqueda">
      <mat-option value="">Sin filtro</mat-option>
      <mat-option value="new">Nuevos</mat-option>
      <mat-option value="dontsave">No guardados</mat-option>
      <mat-option value="negative">Stock negativo</mat-option>
      <mat-option value="empty">Sin stock</mat-option>
    </mat-select>
  </mat-form-field>
  <span class="app-toolbar-filler" *ngIf="ds && existInSql && (!inventory || inventory.state == 'confirm')"></span>
  <button mat-button *ngIf="ds && existInSql && (!inventory || inventory.state == 'confirm')" matTooltip="Añadir nuevo producto" (click)="openDialog()">
    <mat-icon>add</mat-icon>
  </button>
  <span class="app-toolbar-filler"></span>
  <div *ngIf="inventory" (click)="goToOdooInventory()" class="link-class">
    {{inventory.name}} - ({{inventory.location_id.id}}){{inventory.location_id.name}}
  </div>
  <span class="app-toolbar-filler" *ngIf="existInSql && inventory && inventory.state == 'confirm'"></span>
  <!-- <button mat-raised-button *ngIf="existInSql && inventory && inventory.state == 'confirm'" matTooltip="Validar que los datos de SQL concuerdan con odoo" (click)="validateInventory()">Validar</button> -->
  <!-- <button mat-raised-button *ngIf="existInSql && inventory && inventory.state == 'confirm'" matTooltip="Ver datos a actualizar del inventario en odoo" (click)="updateInventory()">Actualizar</button> -->
  <!-- <span class="app-toolbar-filler"></span> -->
  <!-- <button mat-raised-button *ngIf="inventory && inventory.state == 'draft'" matTooltip="Confirmar inventario" (click)="confirm()">Confirmar</button> -->
  <button mat-raised-button *ngIf="ds && inventory && inventory.state == 'confirm' && !existInSql" matTooltip="Guardar productos en la tabla SQL." (click)="saveDataTable()">Guardar productos</button>
  <!-- <button mat-raised-button *ngIf="inventory && inventory.state == 'done' && existInSql" matTooltip="Inventario validado, comprobar si se realizan los cambios en odoo con los datos de SQL." (click)="validateInventoryAdjustment()">Validado - comprobar ajustes de inventario</button> -->
</mat-toolbar>
<mat-sidenav-container class="app-sidenav-container">
  <mat-progress-bar *ngIf="!ds" mode="indeterminate"></mat-progress-bar>
  <mat-drawer-container class="drawer-container">
    <mat-drawer mode="side" opened class="drawer" [disableClose]="true">
      <div *ngIf="ds">
        <div class="num-products">Productos: {{ds.length}}</div>
        <div class="list">
            <cdk-virtual-scroll-viewport itemSize="51" class="viewport" uiVirtualScrollViewportResize>
                <div mat-list-item *cdkVirtualFor="let item of ds" class="item-viewport" [class.active]="validateSelectedItem(item)" (click)="setProduct(item,true)">
                    <div mat-line class="title-cdk" >
                        <div *ngIf="item" [innerHTML]="getName(item) | highlight: ds.toHighlight">
                        </div>
                        <div *ngIf="!item">
                            Loading...
                        </div>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
      </div>
    </mat-drawer>
    <mat-drawer-content class="drawer-content">
      <div *ngIf="activeElement">
        <div class="left-element">
          <div mat-card-avatar>
            <div *ngFor="let url_img of activeElement.images; let i = index">
              <img [src]="url_img" [alt]="activeElement.product_name" (click)="openImage(activeElement.product_name, i)"/>
            </div>
          </div>
        </div>
        <div class="right-element">
          <div class="name">
            <a [href]="getUrlProduct(activeElement)" target="_blank" class="link-class">{{getName(activeElement)}}</a>
          </div>
          <div class="quantities">
            <form class="example-form">
              <table class="example-full-width" cellspacing="0">
                <tr>
                  <td>
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Cantidad teórica</mat-label>
                      <input matInput type="number" id="theoretical_qty" [value]="activeElement.theoretical_qty" [disabled]="true">
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field class="example-full-width" appearance="fill" [class]="isUpdated()">
                      <mat-label>Cantidad del producto</mat-label>
                      <input matInput #qty type="number" id="product_qty" [(ngModel)]="activeElement.product_qty" [ngModelOptions]="{standalone: true}" min="0" (keydown.enter)="$event.target.blur();" [disabled]="inventory.state != 'confirm' || allowUpdate == false">
                    </mat-form-field>
                  </td>
                  <td>
                    <button mat-stroked-button *ngIf="inventory.state == 'confirm'">
                      <input matInput #qty2 type="number" id="qty_plus" min="1" [(ngModel)]="qty_plus" [ngModelOptions]="{standalone: true}" (change)="edited=true;" (keydown.enter)="$event.target.blur();" [style.background-color]="qty_plus!=1 ? 'orange' : ''">
                    </button>
                  </td>
                </tr>
              </table>
            </form>
            <div *ngIf="inventory.state == 'confirm'">
              <button mat-raised-button matTooltip="Guardar cantidad" (click)="saveQty(1)" *ngIf="allowUpdate == true && qtyPreUpdate!=activeElement.product_qty">Guardar</button>
              <button mat-raised-button matTooltip="Permitir guardar cantidad" (click)="selectProductQuantity()" *ngIf="allowUpdate == false">Actualizar</button>
            </div>
          </div>
          <div class="ean">
            <form class="example-form">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Ean 13</mat-label>
                <input matInput type="text" id="ean13" [(ngModel)]="activeElement.product_ean13" [ngModelOptions]="{standalone: true}" (keydown.enter)="$event.target.blur();" [disabled]="inventory.state != 'confirm'">
              </mat-form-field>
            </form>
            <button mat-raised-button matTooltip="Imprimir ean13" (click)="printEan()">Imprimir</button>
            <button mat-raised-button *ngIf="eanOriginal != activeElement.product_ean13" matTooltip="Actualizar ean13" (click)="updateEan()">Actualizar</button>
          </div>
          <mat-selection-list *ngIf="logs.length">
            <div mat-header>Control de cambios de stock</div>
            <mat-list-option *ngFor="let log of logs" [matTooltip]="log.date_add" [style.border]="log.summation === 'none' ? '3px solid orange' : ''">
              <div *ngIf="log.summation === ''">
                Primera cantidad asignada: {{log.qty}}
              </div>
              <div *ngIf="log.summation === 'none'">
                Se asigna cantidad: {{log.qty}}
              </div>
              <div *ngIf="!is_string(log.summation)">
                Se realiza sumatorio: {{log.last_qty}} + <b>{{log.summation}}</b> = {{log.qty}}
              </div>
            </mat-list-option>
          </mat-selection-list>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</mat-sidenav-container>
