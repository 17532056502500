import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AlertService } from 'src/app/services/alert.service';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { SupplierComponent } from '../supplier/supplier.component';
import { Dto, FileTarifa, SupplierTarifa } from 'src/app/types/supplier-tarifa.model';
import { FileComponent } from '../file/file.component';
import { HeaderComponent } from '../header/header.component';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, startWith } from 'rxjs/operators';
import { DtosComponent } from '../dtos/dtos.component';
import { NetosComponent } from '../netos/netos.component';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'app-data',
    templateUrl: './data.component.html',
    styleUrls: ['./data.component.scss'],
    standalone: false
})
export class DataComponent implements OnInit {
  public supplier: SupplierTarifa;
  public suppliers: SupplierTarifa[];
  public files: FileTarifa[] = [];
  public file: FileTarifa;
  public data: any[] = undefined;
  public header: string[];
  public header_to_rename: string[];
  public isLoading: boolean = false;
  public type: string = 'odoo';
  public dtos: Dto[] = [];
  public openInfoData: boolean = false;
  public dataSource;
  @ViewChild(MatSort) sort: MatSort;
  filter = new UntypedFormControl('');
  @ViewChild(MatPaginator, { static: false }) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  constructor(private apiService: ApiService, private alertService: AlertService, public dialog: MatDialog) {
    this.reloadSuppliers();
  }

  reloadSuppliers() {
    this.apiService.getSuppliersTarifa().subscribe(result => {
      if (!result.length) {
        return this.alertService.error("No existen proveedores");
      }

      this.suppliers = result;
    });
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();

    this.filter.valueChanges
      .pipe(
        startWith(''),
        debounceTime(300)
      )
      .subscribe(
        filter => {
          this.dataSource.filter = filter;
        }
      )
  }

  changeSupplier(event: MatSelectChange) {
    if (this.supplier && this.supplier.id == event.value.id) {
      return;
    }

    this.supplier = event.value;
    this.file = null;
    this.getFilesTarifa();
  }

  changeFile(event: MatSelectChange) {
    if (this.file == event.value) {
      return;
    }

    this.file = event.value;
    this.getDataTarifa();
  }

  changeType(event: MatSelectChange) {
    if (this.type == event.value) {
      return;
    }

    this.type = event.value;
    this.getDataTarifa();
  }

  getDataTarifa() {
    this.isLoading = true;
    this.apiService.getDataTarifa(this.supplier.id, this.file.id, this.type).subscribe(result => {
      this.isLoading = false;
      if (result['error']) {
        return this.alertService.error(result['error']);
      }

      this.header = result['header'];
      this.header_to_rename = result['header_to_rename'];
      this.data = result['data'];
      this.dataSource.data = this.data;
    });
  }

  getFilesTarifa() {
    this.apiService.getFilesTarifa(this.supplier.id).subscribe(result => {
      if (!result.length) {
        this.files = [];
        console.error("No existen todavía archivos");
        return;
      }

      this.files = result;
    });
  }

  addDataSupplier() {
    return {
      id: 0,
      name: '',
      categ_id: 0,
      brand_id: 0,
      supplier_id: 0,
      id_supplier: 0,
      search_odoo: '',
      search_prestashop: '',
      date_add: '',
      date_upd: ''
    };
  }

  openInfo() {
    this.openInfoData = !this.openInfoData;
  }

  addDataTarifa() {
    return {
      id: 0,
      id_supplier: this.supplier.id,
      file: '',
      prefix: '',
      delimiter: '',
      dto: 50,
      update_ean: false,
      update_price: false,
      update_whosale_price: false,
      update_category: false,
      date_add: '',
      date_upd: '',
    };
  }

  sortData() {
    if (this.dataSource != null && this.sort && this.dataSource.sort == null) {
      this.dataSource.sort = this.sort;
    }
  }

  openDtos() {
    if (!this.dtos.length) {
      this.dataSource.data.forEach(t => {
        if (typeof t['dto'] != "undefined") {
          const index = this.dtos.findIndex(d => d.value == t['dto']);

          if (index < 0) {
            this.dtos.push({ id: 0, id_file: this.file.id, value: t['dto'], id_supplier: 0, categ_id: 0, dto: 0 });
          }
        }
      });

      if (!this.dtos.length) {
        return this.alertService.warn("Añadir una columna 'dto' en el archivo.")
      } else {
        this.getDtos(this.file.id);
      }
    } else {
      this.getDtos(this.file.id);
    }
  }

  getDtos(id_file: number) {
    this.apiService.getDtos(id_file).subscribe(result => {
      if (!result.length) {
        this.openDto();
        return;
      }

      result.forEach(d => {
        const index = this.dtos.findIndex(r => r.value == d.value);
        if (index > -1) {
          this.dtos[index] = d;
        }
      })

      this.openDto();
    })
  }

  openDialog(component_name, options) {
    return this.dialog.open(component_name, options);
  }

  openDto() {
    this.openDialog(DtosComponent, {
      height: '50vh',
      width: '50%',
      data: this.dtos
    });
  }

  addNeto(element) {
    if (typeof element.reference == "undefined") {
      return this.alertService.error("Añadir la columna 'reference'");
    }

    const dialogRef = this.openDialog(NetosComponent, {
      width: '450px',
      data: { id_file: this.file.id, reference: element['reference'] }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDataTarifa();
      }
    });
  }

  openTarifa(add: boolean = true) {
    if (add) {
      var add_data = this.addDataTarifa();
    }

    const dialogRef = this.openDialog(FileComponent, {
      width: '450px',
      data: add ? add_data : this.file
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getFilesTarifa();
      }
    });
  }

  openColumn(id_column: number, column: string) {
    const dialogRef = this.openDialog(HeaderComponent, {
      width: '250px',
      data: {
        id_column: id_column + 1,
        id_file: this.file.id,
        column
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.id) {
        this.getDataTarifa();
      }
    });
  }

  openSupplier(add: boolean = true) {
    if (add) {
      var add_data = this.addDataSupplier()
    }

    const dialogRef = this.openDialog(SupplierComponent, {
      width: '40%',
      data: add ? add_data : this.supplier
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result['id'] > 0) {
        this.reloadSuppliers();
      }
    });
  }

  getNameColumn(column: string) {
    if (column == 'dto') {
      return 'Tipo descuento';
    }

    return column;
  }

  updateTarifa() {
    const confirm_update = confirm("¿Actualizar en " + this.type + "?");

    if (confirm_update) {
      this.isLoading = true;

      this.apiService.getDataTarifa(this.supplier.id, this.file.id, this.type).subscribe(result => {
        if (result['error']) {
          this.isLoading = false;
          return this.alertService.error(result['error']);
        }

        this.getDataTarifa();
      });
    }
  }
}
