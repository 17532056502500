<h1 mat-dialog-title>Buscar nuevo producto</h1>
<div mat-dialog-content>
  <app-autocomplete [filter]="data.filter" [id_location]="data.id_location" (valueChange)="setProduct($event)"></app-autocomplete>
  <div id="information" *ngIf="selectedProduct">
    <p><b>Producto seleccionado:</b> {{getName(selectedProduct)}}</p>
    <p><b>Cantidad:</b> {{selectedProduct.product_qty}}</p>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Salir</button>
  <button mat-button (click)="closeDialog()">Añadir</button>
</div>
