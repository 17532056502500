import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { Dto } from 'src/app/types/supplier-tarifa.model';
import { SuppliersDtoComponent } from '../suppliers-dto/suppliers-dto.component';

@Component({
    selector: 'app-dtos',
    templateUrl: './dtos.component.html',
    styleUrls: ['./dtos.component.scss'],
    standalone: false
})
export class DtosComponent {
  dtos: Dto[] = [];

  constructor(private apiService: ApiService, private alertService: AlertService, public dialogRef: MatDialogRef<DtosComponent>, @Inject(MAT_DIALOG_DATA) public data: Dto[], public dialog: MatDialog) {
    this.dtos = data;
  }

  saveDto(dto: Dto) {
    this.apiService.saveDto(dto.id_file, dto.value, dto.dto).subscribe(result => {
      console.log(result)

      if (result['error']) {
        return this.alertService.error('No se pudo añadir el descuento.');
      }

      if (!result['result']) {
        return this.alertService.error('No se pudo añadir el descuento.');
      }

      const index = this.dtos.findIndex(r => r.value == dto.value);

      if (index > -1) {
        this.dtos[index].id = result['result'];
      }

      return this.alertService.success('Descuento añadido.');
    })
  }

  updateDto(dto: Dto) {
    if (dto.dto < 1 || dto.dto > 99) {
      return this.alertService.error('No se pudo actualizar el descuento.');
    }

    this.apiService.updateDto(dto.id, dto.dto).subscribe(result => {
      if (!result) {
        return this.alertService.error('No se pudo actualizar el descuento.');
      }

      return this.alertService.success('Descuento actualizado.');
    })
  }

  deleteDto(dto: Dto) {
    this.apiService.deleteDto(dto.id).subscribe(result => {
      if (!result) {
        return this.alertService.error('No se pudo eliminar el descuento.');
      }

      dto.id = 0;
      dto.dto = 0;
      return this.alertService.success('Descuento eliminado.');
    })
  }

  openSuppliers(dto: Dto) {
    const dialogRef = this.dialog.open(SuppliersDtoComponent, {
      height: '25vh',
      width: '25%',
      data: dto
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        dto = result;
      }
    });
  }
}
