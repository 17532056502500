import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, SimpleChange, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { AlertService } from '../services/alert.service';
import { PricesDataSource } from './prices.datasource';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-prices',
    templateUrl: './prices.component.html',
    styleUrls: ['./prices.component.scss'],
    standalone: false
})
export class PricesComponent implements OnInit {
  public dataSource: PricesDataSource;
  public brand: string;
  public displayedColumns = ['id', 'wholesale_price', 'price', 'stock', 'date'];
  public toHighlight: string = '';
  public date = new UntypedFormControl();

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  resizeSubscription$: Subscription;

  constructor(private apiService: ApiService, private alertService: AlertService) { }

  ngOnInit() {
    this.resizeSubscription$ = fromEvent(window, 'resize')
      .pipe(
        debounceTime(250),
        distinctUntilChanged()
      )
      .subscribe(event => this.onResize(event));

    this.dataSource = new PricesDataSource(this.apiService, this.alertService);

    setTimeout(() => {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = this.calcNumRows(window.innerHeight);
      this.sort.sort({ id: 'date', start: 'desc', disableClear: true });
      this.updateTable();
    });
  }

  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe()
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    for (let propName in changes) {
      this[propName] = changes[propName].currentValue;
    }

    this.paginator.pageIndex = 0;
    this.paginator.pageSize = this.calcNumRows(window.innerHeight);
    this.updateTable();
  }

  ngAfterViewInit() {
    fromEvent(this.filter.nativeElement, 'keyup')
      .pipe(
        debounceTime(250),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.paginator.pageIndex = 0;
        this.updateTable();
      });

    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.updateTable();
    });

    this.paginator.page.subscribe(() => this.updateTable());
  }

  //@HostListener('window:resize', ['$event'])
  onResize(event) {
    const newPageSize = this.calcNumRows(event.target.innerHeight)
    if (this.paginator.pageSize !== newPageSize) {
      this.paginator.pageIndex = Math.floor(this.paginator.pageIndex * this.paginator.pageSize / newPageSize);
      this.paginator.pageSize = newPageSize;
      setTimeout(() => this.updateTable());
    }
  }

  updateTable() {
    if (typeof this.filter == "undefined") {
      this.filter = {
        nativeElement: {
          value: ''
        }
      };
    }

    var date = '';

    if (this.date.value != null) {
      date = this.date.value.toISOString();
    }

    if (this.dataSource) {
      this.dataSource.loadData(
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.filter.nativeElement.value,
        date,
        this.sort.direction ? this.sort.active : '',
        this.sort.direction
      );
    }
  }

  save(item, $event, type) {
    if (typeof $event == 'undefined') {
      return;
    }

    if ($event < 1) {
      return this.alertService.warn('No se permite actualizar con ese id');
    }

    let value = 0;
    if (type == 'id_odoo') {
      value = item.id_odoo;
    } else {
      value = item.id_ps;
    }

    if (value == 0 || value == $event) {
      return;
    }

    if (type == 'id_odoo') {
      item.id_odoo = $event;
    } else {
      item.id_ps = $event;
    }

    this.apiService.saveIdsParent(item.ref, item.id_ps, item.id_odoo).subscribe(result => {
      if (result.length) {
        if (type == 'id_odoo') {
          item.id_odoo = value;
        } else {
          item.id_ps = value;
        }

        return this.alertService.warn(result);
      }

      return this.alertService.success('Se actualiza correctamente');
    });
  }

  onSelectBrand(event) {
    this.brand = event;
    this.updateTable();
  }

  calcNumRows(height?) {
    if (typeof height == 'undefined') {
      height = window.innerHeight;
    }
    const toolbar = 64;
    const tableToolbar = 64;
    const tableHeader = 56;
    const tableRow = 36 + 1;//48 + 1;
    const tablePaginator = 56;

    return Math.floor((height - toolbar - tableToolbar - tableHeader - tablePaginator) / tableRow)
  }

  onRowClicked(row) {
  }

  clearStartDate() {
    this.date.setValue(null);
    this.updateTable();
  }
}
