<mat-toolbar color="secundary" class="app-toolbar">
  <button mat-raised-button (click)="updateDataOdoo()" *ngIf="dataSource && type == 'update'">Actualizar</button>
  <span class="app-toolbar-filler"></span>
  <mat-form-field appearance="standard" class="form-field" id="filterSearch" *ngIf="dataSource && showFilters">
    <mat-label>Añadir filtro</mat-label>
    <mat-select [(ngModel)]="filter" (selectionChange)="updateFilter($event)">
      <mat-option [value]="fil.value" *ngFor="let fil of options">{{fil.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-raised-button (click)="returnToPage()">Volver al inventario</button>
</mat-toolbar>
<mat-sidenav-container class="app-sidenav-container">
  <div *ngIf="!dataSource">
    <mat-spinner style="margin:10px auto;"></mat-spinner>
  </div>
  <table id="datasource" mat-table *ngIf="dataSource" [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="id_line">
      <th mat-header-cell *matHeaderCellDef>Id line</th>
      <td mat-cell *matCellDef="let element">{{ element.id_line }}</td>
    </ng-container>

    <ng-container matColumnDef="id_product">
      <th mat-header-cell *matHeaderCellDef>Id product</th>
      <td mat-cell *matCellDef="let element">{{ element.id_product }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Titulo</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="result">
      <th mat-header-cell *matHeaderCellDef>Resultado</th>
      <td mat-cell *matCellDef="let element">{{ element.result }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [hidden]="validateHidden(row)" [style.background]="getColorTr(row)"></tr>
  </table>
</mat-sidenav-container>
