import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { Actions } from 'src/app/types/actions.model';
import { ProductItem } from 'src/app/types/product-item-model';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: false
})
export class TableComponent implements OnChanges {
  @Input() products: ProductItem[];
  @Input() newProducts: ProductItem[];
  @Input() deleted: ProductItem[];
  @Input() type: string;
  @Input() isMulti: boolean;
  @Input() changedRef: boolean;
  @Input() notAction: boolean;
  @Input() ref: string;
  @Input() url_image: string;
  displayedColumns: string[] = ['select', 'ref_supplier', 'internal_ref_supplier', 'name', 'supplier', 'brand', 'stock', 'id_product', 'id_odoo', 'wholesale_price', 'price', 'actions'];
  checked: number[] = [];
  @Output() addProducts: EventEmitter<ProductItem[]> = new EventEmitter();
  @Output() updateRef: EventEmitter<ProductItem[]> = new EventEmitter();
  @Output() unLink: EventEmitter<ProductItem[]> = new EventEmitter();
  @Output() link: EventEmitter<ProductItem[]> = new EventEmitter();
  @Output() searchProduct: EventEmitter<ProductItem> = new EventEmitter();
  dataSource = new MatTableDataSource<ProductItem>();
  selection = new SelectionModel<number>(true, []);
  openGraph = false;
  desactiveAdd = false;

  constructor(private apiService: ApiService, private alertService: AlertService) {
    this.selection.clear();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.products) {
      this.dataSource.data = this.products;
      this.selection.clear();
      this.openGraph = false;
    }

    if (changes.newProducts) {
      this.newProducts.forEach(element => {
        var index = this.dataSource.data.filter(a => a.id == element.id);
        if (!index.length) {
          this.dataSource.data.push(element);
        }
      });

      this.dataSource.data = [...this.dataSource.data];
    }

    if (changes.deleted) {
      this.deleted.forEach(element => {
        this.dataSource.data.forEach((value, index) => {
          if (value.id == element.id) this.dataSource.data.splice(index, 1);
        });
      });

      this.dataSource.data = [...this.dataSource.data];
    }
  }

  isParentProduct(product: ProductItem) {
    if (product.is_parent) {
      return 'parent';
    }
    return '';
  }

  isDisabled(product: ProductItem) {
    if (product.validated == true || this.notAction == true) {
      return true;
    }

    return false;
  }

  validateCheck(product: ProductItem) {
    return product.validated == true;
  }

  setChecked(product: ProductItem) {
    var index = this.checked.indexOf(product.id);
    if (index > -1) {
      this.checked.splice(index, 1);
    } else {
      this.checked.push(product.id);
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => { if (!this.isDisabled(row)) this.selection.select(row.id) });
  }

  actionProducts(action: string, add = false, direct = false, product?: ProductItem) {
    if (this.type == 'products' && this.changedRef) {
      return this.alertService.warn('Debes actualizar la referencia primero.');
    }

    if (!direct && !this.selection.selected.length) {
      return this.alertService.warn('No se seleccionan datos');
    }

    var data: number[];

    if (direct) {
      if (product.is_parent > 0 && this.products.length > 1) {
        return this.alertService.warn('Antes de eliminar el producto padre deberás eliminar los demás productos primero');
      }
      data = [product.id];
    } else {
      data = this.selection.selected
    }

    if ((!this.isMulti && this.type == 'suggestions' && action == 'add') || (!this.isMulti && this.type == 'products' && action == 'delete') || (this.isMulti && this.type == 'products' && product && product.ref == '' && action == 'delete')) {
      const dataToAdd = this.dataSource.data.filter(prod => data.indexOf(prod.id) > -1);
      this.addProducts.emit(dataToAdd);
      this.products = this.dataSource.data.filter(prod => data.indexOf(prod.id) < 0);
      this.dataSource.data = [...this.products];
      return;
    }

    const params: Actions = {
      action: action,
      ref: this.ref,
      data: JSON.stringify(data)
    };

    if (add) {
      params.add = true;
      this.desactiveAdd = true;
    }

    this.apiService.actions(params).subscribe(data => {
      if (data.warning) {
        console.log(data.warning);
      }

      this.desactiveAdd = false;

      if (data.error) {
        return this.alertService.error(data.error);
      }

      const dataToAdd = this.dataSource.data.filter(prod => JSON.parse(params.data).indexOf(prod.id) > -1);

      if (this.isMulti) {
        if (action == 'add') {
          dataToAdd.forEach(prod => {
            prod.ref = params.ref;
            prod.validated = true;
          });
        } else {
          dataToAdd.forEach(prod => {
            prod.ref = '';
            prod.validated = false;
          });
        }
      } else {
        if (action != 'add') {
          dataToAdd.forEach(prod => {
            prod.ref = '';
            prod.validated = false;
          });
        }
      }

      this.selection.clear();

      if (this.type == 'products' && action == 'add') {
        this.dataSource.data.forEach(prod => {
          prod.ref = this.ref
        });

        this.isMulti = true;
        this.link.emit();
      } else {
        this.addProducts.emit(dataToAdd);
        this.products = this.dataSource.data.filter(prod => JSON.parse(params.data).indexOf(prod.id) < 0);
        this.dataSource.data = [...this.products];
      }

      return this.alertService.success('Actualizacion correcta');
    });
  }

  isParent(item: ProductItem) {
    if (item.is_parent > 0) {
      return 'parent';
    }

    return '';
  }

  updateReference() {
    this.updateRef.emit();
  }

  breakBond() {
    if (this.type == 'products' && this.changedRef) {
      return this.alertService.warn('Debes actualizar la referencia primero.');
    }

    this.apiService.breakBond(this.ref).subscribe(result => {
      if (result.length) {
        result.forEach(err => {
          this.alertService.error(err);
        });

        return;
      }

      this.unLink.emit();
      this.alertService.success('Desvinculación correcta');
    });
  }

  updateParent(item: ProductItem) {
    if (item.is_parent > 0) {
      return this.alertService.warn('El producto ya es padre');
    }

    if (!this.ref.length) {
      return this.alertService.warn('No tiene una referencia correcta');
    }

    const params = {
      action: 'changeParent',
      id: `${item.id}`,
      ref: this.ref
    };

    this.apiService.actionsBrand(params).subscribe(data => {
      if (data['error']) {
        return this.alertService.error(data['error']);
      }

      this.dataSource.data.forEach((value, index) => {
        if (value.id == item.id) {
          value.is_parent = 1;
        } else {
          value.is_parent = 0;
        }
      });
    });
  }

  save(item, $event, type) {
    if (typeof $event == 'undefined') {
      return;
    }

    let value = 0;
    if (type == 'id_odoo') {
      value = item.id_odoo;
    } else {
      value = item.id_product;
    }

    if (value == $event) {
      return;
    }

    let var_ps = 0;
    let var_odoo = 0;
    if (type == 'id_odoo') {
      var_odoo = $event;
    } else {
      var_ps = $event;
    }

    this.apiService.changeDirectlyIds(item.id, var_ps, var_odoo, type).subscribe(result => {
      if (typeof result != 'boolean') {
        return this.alertService.warn(result['error']);
      }

      if (result == false) {
        return this.alertService.warn('No se pudo modificar');
      }

      if (type == 'id_odoo') {
        item.id_odoo = $event;
      } else {
        item.id_product = $event;
      }

      return this.alertService.success('Se actualiza correctamente');
    });
  }

  desactiveParent(element) {
    if (typeof element == 'undefined' || element.id < 1) {
      return this.alertService.error('No hay producto seleccionado');
    }

    const params: Actions = {
      action: 'desactiveParent',
      id: element.id
    };

    this.apiService.actions(params).subscribe(data => {
      if (data.error) {
        return this.alertService.error(data.error);
      }

      element.is_parent = 0;
      this.alertService.success('Se actualiza la referencia.');
    });
  }

  openUrlCron(url: string) {
    if (url.length) {
      window.open(url, "_blank");
    }
  }

  openDelete(element: ProductItem) {
    this.openUrlCron("https://www.orbitadigital.com/import/multi-supplier/deleteProductById.php?id=" + element.id)
  }

  openProduct(element: ProductItem) {
    this.searchProduct.emit(element);
  }

  createNewProduct(element: ProductItem) {
    this.apiService.createNewProduct(element.id, element.ref_supplier).subscribe(result => {
      if (!result) {
        return this.alertService.error('No se puede crear el producto');
      }

      element.id_product = result;
    });
  }

  createProductInOdoo(element: ProductItem) {
    if (!confirm("Para crear el producto en odoo es necesario que esté en pretashop creado correctamente. ¿Seguro que quieres crear el producto?")) {
      return;
    }

    this.apiService.createProductInOdoo(element.id, element.id_product).subscribe(result => {
      if (result['error']) {
        return this.alertService.error(result['error']);
      }

      element.id_odoo = result['result'];
    });
  }

  updateImage(element: ProductItem) {
    this.apiService.updateImage(element.id_product, this.url_image).subscribe(result => {
      if (!result) {
        return this.alertService.error('No se pudo actualizar la imagen');
      }
      return this.alertService.success('Se actualiza la imagen');
    });
  }
}
