import { Component, ElementRef, OnInit, ViewChild, SimpleChange } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { AlertService } from '../services/alert.service';
import { ControlDataSource } from './control.datasource';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ControlItem } from '../types/control.model';

@Component({
    selector: 'app-control',
    templateUrl: './control.component.html',
    styleUrls: ['./control.component.scss'],
    standalone: false
})
export class ControlComponent implements OnInit {
  public dataSource: ControlDataSource;
  public supplier: string;
  public displayedColumns = ['id', 'supplier', 'date_start', 'date_end', 'total', 'download', 'discontinued', 'news', 'update', 'errors', 'menu'];
  public toHighlight: string = '';
  public date = '';
  public startDate = null;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  resizeSubscription$: Subscription;

  constructor(private apiService: ApiService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.resizeSubscription$ = fromEvent(window, 'resize')
      .pipe(
        debounceTime(250),
        distinctUntilChanged()
      )
      .subscribe(event => this.onResize(event));

    this.dataSource = new ControlDataSource(this.apiService, this.alertService);
    this.startDate = new Date;
    this.date = this.startDate.toLocaleDateString("en-GB");

    setTimeout(() => {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = this.calcNumRows(window.innerHeight);
      this.updateTable();
    });
  }

  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe()
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    for (let propName in changes) {
      this[propName] = changes[propName].currentValue;
    }

    this.paginator.pageIndex = 0;
    this.paginator.pageSize = this.calcNumRows(window.innerHeight);
    this.updateTable();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.updateTable();
    });

    this.paginator.page.subscribe(() => this.updateTable());
  }

  updateTable() {
    if (typeof this.filter == "undefined") {
      this.filter = {
        nativeElement: {
          value: ''
        }
      };
    }

    if (this.dataSource) {
      this.dataSource.loadData(
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.supplier,
        this.date,
        this.sort.direction ? this.sort.active : '',
        this.sort.direction
      );
    }
  }

  onResize(event) {
    const newPageSize = this.calcNumRows(event.target.innerHeight)
    if (this.paginator.pageSize !== newPageSize) {
      this.paginator.pageIndex = Math.floor(this.paginator.pageIndex * this.paginator.pageSize / newPageSize);
      this.paginator.pageSize = newPageSize;
      setTimeout(() => this.updateTable());
    }
  }

  changeSupplier(supplier) {
    this.supplier = supplier;
    this.updateTable();
  }

  calcNumRows(height?) {
    if (typeof height == 'undefined') {
      height = window.innerHeight;
    }
    const toolbar = 64;
    const tableToolbar = 64;
    const tableHeader = 56;
    const tableRow = 48 + 1;
    const tablePaginator = 56;

    return Math.floor((height - toolbar - tableToolbar - tableHeader - tablePaginator) / tableRow)
  }

  delete(item: ControlItem) {
    if (item.id < 1) {
      return this.alertService.error('Valores incorrectos');
    }

    this.apiService.deleteControl(item.id).subscribe(result => {
      if (!result) {
        return this.alertService.error('No se puede eliminar');
      }

      this.updateTable();
    })
  }

  addDate($event: MatDatepickerInputEvent<Date>) {
    this.startDate = $event.value;
    this.date = $event.value.toLocaleDateString("en-GB");
    this.updateTable();
  }

  clearDate() {
    this.startDate = null;
    this.date = '';
    this.updateTable();
  }

  getbackgroundColor(item: ControlItem) {
    if (item.download == 0) {
      return "#FF968F";
    }

    if (item.errors > 0 || (!item.date_end || !item.date_end.length)) {
      return "#FFF2BD";
    }
  }

  openLink(url) {
    window.open(url, "_blank");
  }
}
