<h2 mat-dialog-title>{{data.id > 0 ? 'Editar proveedor' : 'Añadir nuevo proveedor'}}</h2>

<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Nombre de proveedor</mat-label>
    <input matInput [(ngModel)]="data.name" />
  </mat-form-field>

  <app-search-odoo [value_selected]="data.categ" [search_type]="'product.category'" (valueChange) = "updateSearch($event, 'categ_id')" [placeholder]="'Categoría de odoo (valor por defecto que se asignará al producto)'"></app-search-odoo>
  <app-search-odoo [value_selected]="data.brand" [search_type]="'product.brand'" (valueChange) = "updateSearch($event, 'brand_id')" [placeholder]="'Marca de odoo (valor por defecto que se asignará al producto)'"></app-search-odoo>
  <app-search-odoo [value_selected]="data.supplier" [search_type]="'res.partner'" (valueChange) = "updateSearch($event, 'supplier_id')" [placeholder]="'Proveedor de odoo (valor por defecto que se asignará al producto)'"></app-search-odoo>
  <app-search-odoo [value_selected]="data.supplier_presta" [search_type]="'supplier'" (valueChange) = "updateSearch($event, 'id_supplier')" [placeholder]="'Proveedor de prestashop (valor por defecto que se asignará al producto)'"></app-search-odoo>

  <mat-form-field>
    <mat-label>Busqueda odoo (valor por la que realizará la obtencion de productos en odoo)</mat-label>
    <input matInput [(ngModel)]="data.search_odoo" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Busqueda prestashop (valor por la que realizará la obtencion de productos en prestashop)</mat-label>
    <input matInput [(ngModel)]="data.search_prestashop" />
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cerrar</button>
  <button mat-button (click)="addNew()" cdkFocusInitial *ngIf="data.id < 1">Crear</button>
  <button mat-button (click)="update()" cdkFocusInitial *ngIf="data.id > 0">Actualizar</button>
</div>
