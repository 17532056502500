<mat-toolbar>
  <span *ngIf="dataSource">{{dataSource.length - 1}} Proveedores</span>
  <span class="app-toolbar-filler"></span>
  <button mat-icon-button (click)="getSuppliers()" [matTooltip]="'Actualizar'"><mat-icon>refresh</mat-icon></button>
  </mat-toolbar>
  <table mat-table *ngIf="dataSource" [dataSource]="dataSource" id="table-brands">
    <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns">
      <th mat-header-cell *matHeaderCellDef>
        <span *ngIf="col !== 'isEdit'" [matTooltip]="matTooltip[col]">
          {{translate[col]}}
        </span>
      </th>
      <td mat-cell *matCellDef="let element">
        <div [ngSwitch]="dataSchema[col]" *ngIf="!element.isEdit">
          <div class="btn-edit" *ngSwitchCase="'isEdit'" >
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item *ngIf="element.id != 0" matTooltip="Editar proveedor" (click)="element.isEdit = !element.isEdit"><mat-icon>edit</mat-icon> Editar</button>
              <button mat-menu-item *ngIf="element.id != 0" matTooltip="Eliminar proveedor" (click)="deleteSupplier(element.id)"><mat-icon>delete</mat-icon> Eliminar</button>
              <button mat-menu-item *ngIf="element.id != 0 && element.search == null" matTooltip="Aparece en el filtro 'No vinculados', cuando no se seleccione proveedor" (click)="addSearchSupplier(element)"><mat-icon>login</mat-icon>Buscar</button>
              <button mat-menu-item *ngIf="element.id != 0 && element.search != null" matTooltip="No aparece en el filtro 'No vinculados' si no esta seleccionado un proveedor" (click)="deleteSearchSupplier(element)"><mat-icon>logout</mat-icon>No buscar</button>
              <button mat-menu-item *ngIf="element.id == 0" matTooltip="Añadir nuevo proveedor" (click)="element.isEdit = !element.isEdit"><mat-icon>add_box</mat-icon> Añadir</button>
              <button mat-menu-item *ngIf="element.url_cron_execute && element.url_cron_execute.length" matTooltip="Ejecutar" (click)="openLink(element.url_cron_execute)"><mat-icon>update</mat-icon> Ejecutar</button>
            </mat-menu>
          </div>
          <span *ngSwitchDefault>
            {{element[col]}}
          </span>
        </div>
        <div *ngIf="element.isEdit">
          <div class="btn-edit" *ngIf="col === 'isEdit'; else dataField">
            <button mat-icon-button *ngIf="element.id != 0" matTooltip="Actualizar proveedor" (click)="element.isEdit = updateSupplier(element);"><mat-icon>save</mat-icon></button>
            <button mat-icon-button *ngIf="element.id == 0" matTooltip="Añadir nuevo proveedor" (click)="element.isEdit = addSupplier(element);"><mat-icon>save_alt</mat-icon></button>
          </div>
          <ng-template #dataField>
            <mat-form-field *ngIf="col != 'actions'">
              <mat-label>{{translate[col]}}</mat-label>
              <input *ngIf="col == 'id'" disabled [type]="dataSchema[col]" matInput [(ngModel)]="element[col]" autocomplete="off">
              <input *ngIf="col != 'id'" [type]="dataSchema[col]" matInput [(ngModel)]="element[col]" autocomplete="off">
            </mat-form-field>
          </ng-template>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
