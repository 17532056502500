<h1 mat-dialog-title>Netos</h1>
<div mat-dialog-content *ngIf="neto">
    <mat-form-field>
        <mat-label>Referencia</mat-label>
        <input matInput [value]="neto.reference" readonly>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Precio de compra neto</mat-label>
        <input matInput [(ngModel)]="neto.neto_wholesale_price">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Precio de venta neto</mat-label>
        <input matInput [(ngModel)]="neto.neto_price">
    </mat-form-field>
    <app-search-odoo [value_selected]="neto.categ" [search_type]="'product.category'" (valueChange) = "updateSearch($event, 'categ_id')" [placeholder]="'Categoría en odoo'"></app-search-odoo>
    <app-search-odoo [value_selected]="neto.supplier_presta" [search_type]="'supplier'" (valueChange) = "updateSearch($event, 'id_supplier')" [placeholder]="'Proveedor de prestashop'"></app-search-odoo>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cerrar</button>
  <button mat-button (click)="addNew()" cdkFocusInitial>Guardar</button>
</div>