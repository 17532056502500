
<mat-toolbar color="primary" class="app-toolbar">
  <button mat-icon-button (click)="sidenav.toggle()">
    <mat-icon><span class="material-icons">menu</span></mat-icon>
  </button>
  <h1 class="app-name">Inventario</h1>
</mat-toolbar>

<mat-sidenav-container class="app-sidenav-container">
  <mat-sidenav #sidenav [mode]="mobileQuery.matches ? 'push' : 'push'" [fixedInViewport]="true" [fixedTopGap]="mobileQuery.matches ? '56' : '64'">
    <mat-nav-list>
        <a mat-list-item routerLink="create">Crear</a>
    </mat-nav-list>
    <mat-nav-list>
        <a mat-list-item routerLink="products">Productos de inventario</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="app-content">
      <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
