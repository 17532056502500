import { Component, Input, Host, OnInit } from '@angular/core';
import { SatPopover } from '@ncstate/sat-popover';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-inline-edit',
    templateUrl: './inline-edit.component.html',
    styleUrls: ['./inline-edit.component.scss'],
    standalone: false
})
export class InlineEditComponent implements OnInit {

  @Input() where: string
  @Input() title: string

  @Input()
  get value(): string { return this._value; }
  set value(x: string) {
    this._value = x;
  }
  private _value = '';

  constructor(@Host() public popover: SatPopover) { }

  ngOnInit() {
    // subscribe to cancellations and reset form value
    this.popover.closed.pipe(filter(val => val == null)).subscribe(() => this.value || {});
  }

  onSave() {
    this.popover.close(this.value);
  }

  onCancel() {
    this.popover.close();
  }
}
