
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';

// Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Material
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// Locale
import localeEs from '@angular/common/locales/es';

// Platform Browser
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Routing
import { APP_ROUTING } from './app.routes';

// Services
import { ApiService } from './services/api.service';
import { AlertService } from './services/alert.service';

// Pipes
import { HighlightPipe } from 'src/app/pipes/highlight.pipe';

// Components
import { AppComponent } from './app.component';
import { SearchComponent } from './search/search.component';
import { ProductsComponent } from './products/products.component';
import { CreateComponent } from './create/create.component';
import { NewProductComponent } from './new-product/new-product.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { ActionsComponent } from './actions/actions.component';
import { ShowImageComponent } from './show-image/show-image.component';

registerLocaleData(localeEs, 'es')

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    ProductsComponent,
    CreateComponent,
    HighlightPipe,
    NewProductComponent,
    AutocompleteComponent,
    ActionsComponent,
    ShowImageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    APP_ROUTING,
    HttpClientModule,
    MatToolbarModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatButtonModule,
    MatTooltipModule,
    MatSnackBarModule,
    ScrollingModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatProgressBarModule
  ],
  providers: [ApiService, AlertService, { provide: LocationStrategy, useClass: HashLocationStrategy }, { provide: LOCALE_ID, useValue: 'es-Ar' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
