import { RouterModule, Routes } from '@angular/router';
import { BrandsComponent } from './brands/brands.component';
import { LogsListComponent } from './logs-list/logs-list.component';
import { SearchComponent } from './search/search.component';
import { RulesListComponent } from './rules-list/rules-list.component';
import { ShowPricesComponent } from './show-prices/show-prices.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { ParentsComponent } from './parents/parents.component';
import { SupplierBrandsComponent } from './supplier-brands/supplier-brands.component';
import { CheckComponent } from './check/check.component';
import { ExceptionsComponent } from './exceptions/exceptions.component';
import { ControlComponent } from './control/control.component';
import { PricesComponent } from './prices/prices.component';
import { ConfigComponent } from './config/config.component';
import { OdooProductsComponent } from './odoo-products/odoo-products.component';
import { ControlEmployeesComponent } from './control-employees/control-employees.component';
import { DataComponent } from './tarifa/data/data.component';

const APP_ROUTES: Routes = [
  { path: 'search', component: SearchComponent },
  { path: 'logs', component: LogsListComponent },
  { path: 'brands', component: BrandsComponent },
  { path: 'rules', component: RulesListComponent },
  { path: 'suppliers', component: SuppliersComponent },
  { path: 'parents', component: ParentsComponent },
  { path: 'show-prices', redirectTo: 'show-prices/', pathMatch: 'full' },
  { path: 'show-prices/:search', component: ShowPricesComponent },
  { path: 'new-brands', component: SupplierBrandsComponent },
  { path: 'check', component: CheckComponent },
  { path: 'exceptions', component: ExceptionsComponent },
  { path: 'exceptions/:item', component: ExceptionsComponent },
  { path: 'odoo-products', component: OdooProductsComponent },
  { path: 'control', component: ControlComponent },
  { path: 'prices', component: PricesComponent },
  { path: 'config', component: ConfigComponent },
  { path: 'employees', component: ControlEmployeesComponent },
  { path: 'tarifa', component: DataComponent },
  { path: '', redirectTo: 'config', pathMatch: 'full' },
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);
