import { Component, OnChanges, Input, ViewChild, EventEmitter, Output, SimpleChanges, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { finalize, switchMap, tap, debounceTime } from 'rxjs/operators';
import { MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ApiService } from 'src/app/services/api.service';
import { AlertService } from 'src/app/services/alert.service';
import { HighlightPipe } from 'src/app/pipes/highlight.pipe';
import { ProductSql } from 'src/app/types/productSql.model';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnChanges {
  @ViewChild(MatAutocompleteTrigger) autoProduct: MatAutocompleteTrigger;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  isLoadingProducts = false;
  dataProducts: ProductSql[];
  toHighlight = '';
  selectedProduct: ProductSql;
  @Input() filter: string = '';
  @Input() id_location: number;

  constructor(private apiService: ApiService, public alertService: AlertService, @Inject(DOCUMENT) document) {
    this.form = new FormGroup({
      autocompleteProduct: new FormControl()
    });

    this.form.setValue({
      autocompleteProduct: ''
    });

    this.form
      .get('autocompleteProduct')
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoadingProducts = true),
        switchMap(value => this.apiService.searchNewProducts(this.getCorrectFilter(value), this.id_location)
          .pipe(
            finalize(() => this.isLoadingProducts = false),
          )
        )
      )
      .subscribe((data) => {
        if (data['error']) {
          return this.showError(data['error']);
        }

        if (typeof data == "boolean" && data == false) {
          return this.showError('Error al buscar productos nuevos');
        }

        this.dataProducts = data.result;
        this.toHighlight = data.search;
        this.autoProduct.openPanel();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter && typeof this.filter != 'undefined') {
      this.form.setValue({
        autocompleteProduct: this.filter
      });
    }
  }

  showError(error) {
    this.dataProducts = [];
    return this.alertService.error(error);
  }

  getCorrectFilter(value) {
    if (typeof value != "string") {
      return '';
    }

    return value;
  }

  displayProduct(searchResult) {
    if (typeof searchResult.product_ean13 != "undefined") {
      return searchResult.product_code;
    }
    return searchResult;
  }

  getName(searchResult: ProductSql) {
    if (typeof searchResult.product_ean13 != "undefined") {
      if (searchResult.product_ean13.length) {
        return "(" + searchResult.product_ean13 + ") [" + searchResult.product_code + "] " + searchResult.product_name;
      }
      return "[" + searchResult.product_code + "] " + searchResult.product_name;
    }
  }

  onSelectProduct(event: MatAutocompleteSelectedEvent) {
    if (event.option.value.product_id > 0) {
      this.selectedProduct = event.option.value;
      let add = false;
      if (this.dataProducts.length == 1) {
        add = true;
      }

      this.valueChange.emit({
        'product': this.selectedProduct,
        'add': add
      });
    }
  }
}
