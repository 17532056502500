import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { ProductItem } from "../types/product-item-model";

@Component({
    selector: 'app-show-prices',
    templateUrl: './show-prices.component.html',
    styleUrls: ['./show-prices.component.scss'],
    standalone: false
})
export class ShowPricesComponent implements OnInit {
  public search: string;
  public ref = '';
  public products: ProductItem[] = [];
  public is_multi = false;
  public error = '';

  constructor(private route: ActivatedRoute, public alertService: AlertService, public apiService: ApiService) {
    this.products = [];
  }

  ngOnInit() {
    this.search = this.route.snapshot.paramMap.get('search');
    if (typeof this.search != "undefined" && (!this.search.length || this.search == '0')) {
      return this.alertService.warn('No se le pasa un valor de busqueda correcto.');
    }

    this.apiService.showPrices(this.search).subscribe(result => {
      if (result.error) {
        this.error = result.error;
        return this.alertService.error(result.error);
      }

      this.products = result.data;
      this.is_multi = result.is_multi;
      this.ref = result.ref;
    });
  }

}
