<mat-toolbar>
    <mat-form-field class="margin-top">
        <mat-label>Filtro</mat-label>
        <input matInput placeholder="" type="text" #filter>
        <button *ngIf="existFilter()" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <span class="app-toolbar-filler"></span>
    <mat-form-field class="margin-top hide-hint">
      <mat-label>Empleado</mat-label>
      <mat-select (selectionChange)="changeEmployee($event)">
          <mat-option [value]="'0'">-</mat-option>
          <mat-option [value]="option.id_employee" *ngFor="let option of employees">{{ option.firstname + " " + option.lastname }}</mat-option>
      </mat-select>
    </mat-form-field>
    <span class="app-toolbar-filler"></span>
    <mat-form-field class="margin-top hide-hint">
      <mat-label>Proyecto</mat-label>
      <mat-select (selectionChange)="changeProject($event.value)">
          <mat-option [value]="''">-</mat-option>
          <mat-option [value]="option" *ngFor="let option of projects">{{ option }}</mat-option>
      </mat-select>
    </mat-form-field>
    <span class="app-toolbar-filler"></span>
    <mat-form-field>
      <input matInput [matDatepicker]="picker" [formControl]="date" (dateChange)="getData()">
      <mat-datepicker #picker disabled="false"></mat-datepicker>
      <mat-datepicker-toggle matSuffix (click)="clearDate()" *ngIf="date.value !== null">
        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker-toggle matSuffix matIconSuffix [for]="picker"></mat-datepicker-toggle>
    </mat-form-field>
</mat-toolbar>

<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="date" matSortDirection="desc" matSortDisableClear>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>
        <ng-container matColumnDef="id_employee">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Empleado </th>
            <td mat-cell *matCellDef="let row"> {{row.firstname}} {{row.lastname}}</td>
        </ng-container>
        <ng-container matColumnDef="project">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Proyecto </th>
            <td mat-cell *matCellDef="let row"> {{row.project}} </td>
        </ng-container>
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Acción </th>
            <td mat-cell *matCellDef="let row"> {{row.action}} </td>
        </ng-container>
        <ng-container matColumnDef="query">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Consulta </th>
            <td mat-cell *matCellDef="let row"> {{row.query}} </td>
        </ng-container>
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
            <td mat-cell *matCellDef="let row"> {{row.date}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="dataSource.total" [pageSize]="dataSource._pageSize" showFirstLastButtons></mat-paginator>
</div>
