import { Component, OnInit } from '@angular/core';
import { AlertService } from '../services/alert.service';
import { ApiService } from '../services/api.service';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  public filters = [
    { value: 'none', name: 'Todos los productos' },
    { value: 'partial', name: 'Selección manual de productos' },
    { value: 'product', name: 'Sólo un producto' },
    { value: 'lot', name: '' }]; // TODO demas casos
  public filter: string;
  public locations = [
    { value: 12, name: 'WH/Existencias' },
    { value: 30, name: 'WH/Reacondicionado' }]; // TODO demas casos
  public location: number;
  public name = '';
  public loaded = true;

  constructor(private apiService: ApiService, private alertService: AlertService, private router: Router) {
    this.filters = [{ value: 'none', name: 'Todos los productos' }];
  }

  ngOnInit(): void {
  }

  add() {
    if (!this.name.trim().length) {
      return this.alertService.warn('No tiene un nombre correcto.');
    }

    if (typeof this.location == 'undefined') {
      return this.alertService.warn('Seleccione una ubicación inventariada.');
    }

    if (typeof this.filter == 'undefined') {
      return this.alertService.warn('Seleccione inventario.');
    }

    this.loaded = false;
    this.apiService.add(this.name.trim(), this.filter, this.location).subscribe(result => {
      this.loaded = true;
      if (result > 0) {
        return this.router.navigate(['/products/' + result]);
      }

      return this.alertService.error(result);
    }, (error) => {
      this.loaded = true;
      this.apiService.validateError(error, " al crear el inventario.");
    });
  }
}
