import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class InfoComponent {
  labels = [
    {
      panel_title: "1 Proveedores",
      panel: [
        {
          panel_title: "1.1 Añadir un proveedor",
          panel_text: `
          <p>Hacer click en el icono <span class=\"material-icons\">add_box</span></p>
          <p>Para ello necesitarás rellenar:</p>
          <ul>
            <li><b>Nombre del proveedor:</b> es el nombre del proveedor.</li>
            <li><b>Categoría:</b> es una categoría existente en odoo.</li>
            <li><b>Marca:</b> es una marca existente en odoo.</li>
            <li><b>Proveedor odoo:</b> es un proveedor existente en odoo.</li>
            <li><b>Proveedor prestashop:</b> es un proveedor existente en prestashop.</li>
            <li><b>Busqueda de odoo:</b> será el prefijo para la busqueda de productos en odoo. (Ejemplo: televes-)</li>
            <li><b>Busqueda de prestashop:</b> será el prefijo para la busqueda de productos en prestashop. (Ejemplo: golmar-)</li>
          </ul>
          <p>Cuando se quiere guardar los datos se hará click en <b>\"Crear\"</b>.</p>
          `
        },
        {
          panel_title: "1.2 Seleccionar un proveedor",
          panel_text: `
          <p>En el selector <b>\"Proveedores\"</b> permite seleccionar un proveedor.</p>
          <p>Al seleccionar un proveedor podrás añadir archivos (csv) para ese proveedor.</p>
          `
        },
        {
          panel_title: "1.3 Editar un proveedor",
          panel_text: `
          <p>Para ello deberás seleccionar un proveedor (<b>1.2</b>)</p>
          <p>Hacer click en el icono <span class=\"material-icons\">update</span></p>
          <p>Editar los campos que se quiera y hacer click en <b>\"Actualizar\"</b></p>
          `
        }
      ]
    },
    {
      panel_title: "2 Archivos",
      panel: [
        {
          panel_title: "2.1 Añadir un archivo",
          panel_text: `
          <p>Es necesario seleccionar un proveedor (<b>1.2</b>)</p>
          <p>Hacer click en el icono <span class=\"material-icons\">my_library_add</span></p>
          <p>Para ello necesitarás rellenar:</p>
          <ul>
            <li>Prefijo de la referencia del archivo si fuese necesario (Ejemplo: <b>fermax-</b>{referencia})</li>
            <li>Añadir un archivo con extensión csv. Precios con separador de decimal \",\".</li>
            <li>(<b>2.1.1</b>) Descuento (%): valor mayor que 0 y menor que 100. 
            <br>Se utilizará para calcular el precio de compra (precio de venta por el descuento).</li>
            <li>Delimitador CSV (separador de columnas): \",\" o \";\".</li>
            <li>(<b>2.1.2</b>) Actualizar ean: activar si se quiere actualizar el ean en odoo o prestashop.</li>
            <li>(<b>2.1.3</b>) Actualizar precio de compra: activar si se quiere actualizar el wholesale_price en odoo o prestashop.</li>
            <li>(<b>2.1.4</b>) Actualizar precio de venta: activar si se quiere actualizar el price en odoo o prestashop.</li>
            <li>(<b>2.1.5</b>) Actualizar categoria: activar si se quiere actualizar el dto (categoría interna o proveedor) en odoo o prestashop.</li>
          </ul>
          <p>Cuando se quiere guardar los datos se hará click en <b>\"Crear\"</b>.</p>
          `
        },
        {
          panel_title: "2.2 Seleccionar un archivo",
          panel_text: `
          <p>Para ello deberás seleccionar un proveedor (<b>1.2</b>)</p>
          <p>En el selector <b>\"Archivos\"</b> permite seleccionar un archivo.</p>
          <p>Al seleccionar un archivo podrás ver el contenido del archivo.</p>
          `
        },
        {
          panel_title: "2.3 Editar un archivo",
          panel_text: `
          <p>Para ello deberás seleccionar un archivo (<b>2.2</b>)</p>
          <p>Hacer click en el icono <span class=\"material-icons\">system_update_alt</span></p>
          <p>Editar los campos que se quiera y hacer click en <b>\"Actualizar\"</b></p>
          `
        }
      ]
    },
    {
      panel_title: "3 Renombrar columnas",
      panel: [
        {
          panel_title: "3.1 Seleccionar una columna",
          panel_text: `
          <p>Para ello deberás seleccionar un archivo (<b>2.2</b>)</p>
          <p>Existirá un selector llamada \"Seleccionar columna\" donde podrás seleccionar la columna a editar (<b>3.2</b>)</p>
          `
        },
        {
          panel_title: "3.2 Editar una columna",
          panel_text: `
          <p>Para ello deberás seleccionar una columna (<b>3.1</b>)</p>
          <p>Existirá un selector llamada \"Seleccionar columna\" donde seleccionarás la columna que se quiera editar</p>

          <p>Se abrirá una pantalla donde se podrá seleccionar un valor nuevo. 
          <br>Ejemplo: el proveedor nos pasa una columna llamada 'PVP' y para nosostros es el precio de venta (price), el valor que deberemos poner será 'price'</p>
          <p>Renombres:</p>
          <ul>
            <li><b>reference:</b> valor por el cual se relacionarán los productos en odoo y prestashop, tambien se le añadirá el <b>prefijo</b> que se definió en (<b>2.1</b>)</li>
            <li><b>upc:</b> la columna que contenga el upc de los productos. Tiene que ser un valor numérico mayor que 0. 
            <br>Multiplicará el precio de compra por el upc y el precio de venta por el upc. Solo se aplicará en la tarifa de prestashop.</li>
            <li>(<b>3.2.1</b>) <b>ean:</b> la columna que contenga los enas.</li>
            <li>(<b>3.2.2</b>) <b>wholesale_price:</b> columna que contenga el precio de compra. Si existe este valor no se tendrá en cuenta los descuentos (<b>2.1.1</b>) (<b>4.2</b>).</li>
            <li>(<b>3.2.3</b>) <b>price:</b> columna que contenga el precio de venta.</li>
            <li><b>dto:</b> columna con los descuentos aplicados.
            <br>(Ejemplo: si los descuentos de la tarifa son distintos para cada tipo de producto (Como pasa con las familias de Golmar)).
            <br>Mas infomación (<b>4.2</b>) </li>
          </ul>
          <p>Nuevas columnas(<b>8</b>)</p>
          `
        }
      ]
    },
    {
      panel_title: "4 Columna \"dto\"",
      panel: [
        {
          panel_title: "4.1 Abrir la columna dto",
          panel_text: `
          <p>Para ello deberás tener una columna llamada \"dto\" (<b>3.2</b>)</p>
          <p>Hacer click en el icono <span class=\"material-icons\">monetization_on</span></p>
          <p>Aparecerá una lista de valores únicos de la columna \"dto\"</p>
          `
        },
        {
          panel_title: "4.2 Guardar descuento",
          panel_text: `
          <p>Para ello deberás abrir los descuentos (<b>4.1</b>)</p>
          <p>Te aparecerá un listado de los diferenntes descuentos</p>
          <p>Con los campos:</p>
          <ul>
            <li><b>Grupo de descuento:</b> es el tipo de descuento </li>
            <li><b>Descuento:</b> es el descuento mayor que 0 y menor que 100 que se aplicará</li>
            <li>
              <span class=\"material-icons\">local_offer</span>: te permitirá asignar el proveedor de prestashop o la categoria de odoo a un grupo de descuentos.
              <br>Se abrirá una pantalla con estos valores:
              <ul>
                <li>Categoría en odoo: la categoría de odoo que se asignará al grupo de descuento seleccionado.
                <br>Se guardará el valor cuando se seleccione un valor.</li>
                <li>Proveedor de prestashop: la categoría de odoo que se asignará al grupo de descuento seleccionado.
                <br>Se guardará el valor cuando se seleccione un valor.</li>
              </ul>
            </li>
            <li><span class=\"material-icons\">save</span>: hacer click para guardar el <b>descuento</b>.</li>
          </ul>
          Las filas que pesea un descuento estará de color <span class='dto-color'>.....</span>
          `
        }
      ]
    },
    {
      panel_title: "5 Tipo de actualización",
      panel: [
        {
          panel_title: "5.1 Actualización en odoo",
          panel_text: `
          <p>Para ello deberás seleccionar un archivo (<b>2.2</b>)</p>
          <p>En la parte superior de la pantalla hay selector llamado \"Tipo\", se seleccionará \"Odoo\" (Está preseleccionado).</p>
          `
        },
        {
          panel_title: "5.2 Actualización en prestashop",
          panel_text: `
          <p>Para ello deberás seleccionar un archivo (<b>2.2</b>)</p>
          <p>En la parte superior de la pantalla hay selector llamado \"Tipo\", se seleccionará \"Prestashop\".</p>
          `
        }
      ]
    },
    {
      panel_title: "6 Busqueda",
      panel: [
        {
          panel_title: "6.1 Buscar",
          panel_text: `
          <p>Para ello deberás seleccionar un archivo (<b>2.2</b>)</p>
          <p>En la parte superior de la pantalla hay un elemento llamado \"Busqueda\" que te permitirá buscar en toda la tabla.</p>
          `
        }
      ]
    },
    {
      panel_title: "7 Netos",
      panel: [
        {
          panel_title: "7.1 Asignar netos",
          panel_text: `
          <p>Para ello deberás seleccionar un archivo (<b>2.2</b>)</p>
          <p>En la tabla, en cada una de las referencias se podrá hacer click en el icono <span class=\"material-icons\">more_vert</span></p>
          <p>Aparecerá un elemento con los campos:</p>
          <ul>
            <li><b>Referencia:</b> es el valor al que se va a asignar los netos</li>
            <li><b>Precio de compra neto:</b> el valor de precio de compra especifico sin IVA.</li>
            <li><b>Precio de venta neto:</b> el valor de precio de venta especifico sin IVA.</li>
            <li><b>Categoría en odoo:</b> la categoría de odoo que se asignará al grupo de descuento seleccionado. <br>Se guardará el valor cuando se seleccione un valor.</li>
            <li><b>Proveedor de prestashop:</b> la categoría de odoo que se asignará al grupo de descuento seleccionado. <br>Se guardará el valor cuando se seleccione un valor.</li>
          </ul>
          <p>Las filas que pesea un neto estará de color <span class='neto-color'>.....</span></p>
          `
        },
        {
          panel_title: "7.2 Eliminar neto",
          panel_text: `
          <p>Para ello deberás seleccionar un archivo (<b>2.2</b>)</p>
          <p>Las filas que pesea un neto estará de color <span class='neto-color'>.....</span></p>
          <p>En la tabla, en cada una de las referencias se podrá hacer click en el icono <span class=\"material-icons\">more_vert</span></p>
          <p>hacer click en <b>\"Eliminar\"</b>
          `
        }
      ]
    },
    {
      panel_title: "8 Nuevas columnas en la tabla",
      panel: [
        {
          panel_title: "8.1 wholesale_price",
          panel_text: `
          <p>Es el precio de compra que se calcula mediante la columna price (<b>3.2.3</b>) y el descuento (<b>8.2</b>).</p>
          `
        },
        {
          panel_title: "8.2 dto %",
          panel_text: `
          <p>Es el descuento que se aplica en el producto, en %.</p>
          `
        },
        {
          panel_title: "8.3 diferencia PVP",
          panel_text: `
          <p>Es el porcentaje de diferencia entre los pvp anterior y el pvp nuevo.</p>
          `
        },
        {
          panel_title: "8.4 Cambios",
          panel_text: `
          <p>Es la columna que te mostrará los cambios que realizará.</p>
          <p>Odoo:</p>
          <ul>
            <li><b>price: (valor_1 -> valor_2):</b> modificará el precio de venta. valor_1: precio de venta actual. valor_2: precio de venta nuevo, el valor que se actualizará.</li>
            <li><b>wholesale_price: (valor_1 -> valor_2):</b> modificará el precio de compra.
            <br>valor_1: precio de compra actual. valor_2: precio de compra nuevo, el valor que se actualizará.<br>Si existe stock en odoo no se actualizará, se verá reflejado como "EN STOCK".</li>
            <li><b>categ_id: (valor_1 -> valor_2):</b> modificará la categoría.
            <br>valor_1: categoría actual. valor_2: categoría nueva, el valor que se actualizará.</li>
            <li><b>state: (valor_1 -> sellable):</b> modificará el estado del producto, productos que estan en el csv pero en odoo estan en state = obsolete.
            <br>valor_1: estado actual. valor_2: estado nuevo (sellable), el valor que se actualizará.</li>
            <li><b>state: (valor_1 -> obsolete):</b> modificará el estado del producto, productos que no estan en el csv.
            <br>valor_1: estado actual. valor_2: estado nuevo (absolete), el valor que se actualizará.</li>
            <li><b>state: (valor_1 -> end):</b> modificará el estado del producto, productos que no estan en el csv pero tienen stock en odoo.
            <br>valor_1: estado actual. valor_2: estado nuevo (end), el valor que se actualizará.</li>
            <li><b>Create:</b> es un producto nuevo en odoo.</li>
          </ul>
          <p>Prestashop:</p>
          <ul>
            <li><b>price: (valor_1 -> valor_2):</b> modificará el precio de venta. valor_1: precio de venta actual. valor_2: precio de venta nuevo, el valor que se actualizará.</li>
            <li><b>wholesale_price: (valor_1 -> valor_2):</b> modificará el precio de compra.
            <br>valor_1: precio de compra actual. valor_2: precio de compra nuevo, el valor que se actualizará.<br>Si existe stock en odoo no se actualizará, se verá reflejado como "EN STOCK".</li>
            <li><b>supplier_id: (valor_1 -> valor_2):</b> modificará rl proveedor.
            <br>valor_1: proveedor actual. valor_2: proveedor nueva, el valor que se actualizará.</li>
            <li><b>delete-stock (quantity):</b> Producto que no está en el csv pero no se descataloga porque existe cantidad en odoo. quantity: cantidad actual de odoo.</li>
            <li><b>quantity: (valor):</b> modificará la cantidad del producto en odoo en prestashop.</li>
            <li><b>id_category_default: 104:</b> productos que no estan en el csv.</li>
            <li><b>Create:</b> es un producto nuevo en Prestashop, pero no se creará nunca.</li>
          </ul>
          `
        }
      ]
    },
    {
      panel_title: "9 Actualización de datos",
      panel: [
        {
          panel_title: "9.1 Actualizar",
          panel_text: `
          <p>Para ello deberás seleccionar un archivo (<b>2.2</b>) y hacer click en <span class=\"material-icons\">import_export</span>. Aparecerá una confirmación de la acción.</p>
          `
        }
      ]
    },
  ]

  constructor() { }
}
