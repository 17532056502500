import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutocompleteComponent } from './../autocomplete/autocomplete.component';
import { ProductSql } from 'src/app/types/productSql.model';
import { ApiService } from 'src/app/services/api.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.scss']
})
export class NewProductComponent {
  public selectedProduct: ProductSql;

  constructor(public dialogRef: MatDialogRef<NewProductComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiService, public alertService: AlertService,) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setProduct(event: any) {
    this.selectedProduct = event.product;
    if (event.add) {
      this.closeDialog();
    }
  }

  getName(searchResult: ProductSql) {
    if (typeof searchResult.product_ean13 != "undefined") {
      if (searchResult.product_ean13.length) {
        return "(" + searchResult.product_ean13 + ") [" + searchResult.product_code + "] " + searchResult.product_name;
      }
      return "[" + searchResult.product_code + "] " + searchResult.product_name;
    }
  }

  closeDialog(): void {
    if (typeof this.selectedProduct != "undefined") {
      this.apiService.createNewLine(this.selectedProduct, this.data.id_inventory).subscribe(result => {
        if (result['error']) {
          return this.alertService.error(result['error']);
        }

        if (result == false) {
          return this.alertService.error('Error al guardar');
        }

        setTimeout(() => {
          this.dialogRef.close(result);
        }, 200);
      });
    } else {
      this.dialogRef.close();
    }
  }
}
